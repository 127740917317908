const validateContact = (contact) => {
	const phoneRegex =  /^(\d{10}|\d{11}|\d{12})$/;
	if (!contact) {
		return "Contact Number is required";
	} else if (!phoneRegex.test(contact)) {
		return "Contact Number is not valid";
	}
	return "";
};

const validateState = (state) => {
	if (!state || state === "Select State") {
		return "State is required.";
	}
	return "";
};

const validateDistrict = (district) => {
	if (!district || district === "Select District") {
		return "District is required.";
	}
	return "";
};

const validateGender = (gender) => {
	if (!gender || gender === "Select Gender") {
		return "Gender is required.";
	}
	return "";
};

const validateEmail = (email, text = "Username") => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	if (!email || email === "") {
		return `${text} is required`;
	} else if (!emailRegex.test(email)) {
		return `Invalid ${text}`;
	}
	return "";
};

const validateAge = (age) => {
	const ageRegex = /^(10|[1-9][0-9]|100)$/; // Matches age between 10 and 100
	if (!age) {
		return "Age is required";
	} else if (!ageRegex.test(age)) {
		return "Age must be between 10 and 100";
	}
	return "";
};

const validatePassword = (password) => {
	if (!password || password === "") {
		return "Password is required";
	}
	return "";
};

const validateName = (input, field) => {
	let specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
	if (!input || input === "") {
		return field + " is required";
	} else if (specialChars.test(input)) {
		console.log("Special character not allowed except");
		return "Special character not allowed except _";
	}
	return "";
};

//Name validation - not a required field
const validateRefreeName = (input) => {
	let specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
	if (input) {
		if (specialChars.test(input)) {
			return "Special character not allowed except _";
		}
		return "";
	}
	return "";
};
//contact validation - not a required field
const validateRefreeContact = (contact) => {
	const phoneRegex = /^[0-9]{10}$/; // Example for a 10-digit phone number
	if (contact) {
		if (!phoneRegex.test(contact)) {
			return "Contact Number is not valid";
		}
		return "";
	}
	return "";
};

module.exports = {
	validateContact,
	validateState,
	validateDistrict,
	validateEmail,
	validatePassword,
	validateName,
	validateAge,
	validateGender,
	validateRefreeContact,
	validateRefreeName,
};
