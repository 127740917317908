export const dailyTasks = [
	{
		taskId: 1,
		taskTitle: "Shared Videos of Allama in FB Groups?",
		taskTitle2: "Shared Videos of Allama in dd FB Groups",
		taskName: "shareVideosOfAllama",
		taskDescription:
			"Videos of Allama Shared in at least 2 district-specific FB Groups",
		dd: 1,
	},
	{
		taskId: 2,
		taskTitle: "Shared Videos of Allama in FB Groups?",
		taskTitle2: "Shared Videos of Allama in dd2 FB Groups",
		taskName: "shareVideosOfAllamaD2",
		taskDescription:
			"Videos of Allama Shared in at least 2 district-specific FB Groups",
		dd: 2,
	},
	{
		taskId: 3,
		taskTitle: "Liked and Commented on FB Page?",
		taskTitle2: "Liked and Commented on FB Page",
		taskName: "likeAndComment",
		taskDescription: "Liked and Commented on a Post on Facebook Page of Allama",
		dd: 0,
	},
	{
		taskId: 4,
		taskTitle: "Mentioned Individuals in Comments?",
		taskTitle2: "Mentioned Individuals in Comments",
		taskName: "mentionRelatedIndividuals",
		taskDescription:
			"Mentioned related individuals in comments under Allama’s videos on FB & YT",
		dd: 0,
	},
	{
		taskId: 5,
		taskTitle: "Remixed Reels and Shorts?",
		taskTitle2: "Remixed Reels and Shorts with dd tagged as the location",
		taskName: "remixedReelsAndShorts",
		taskDescription:
			"Uploaded Shorts on YouTube & Insta by tagging location of assigned district",
		dd: 1,
	},
	{
		taskId: 6,
		taskTitle: "Remixed Reels and Shorts?",
		taskTitle2: "Remixed Reels and Shorts with dd2 tagged as the location",
		taskName: "remixedReelsAndShortsD2",
		taskDescription:
			"Uploaded Shorts on YouTube & Insta by tagging location of assigned district",
		dd: 2,
	},
	{
		taskId: 7,
		taskTitle: "Shared videos on WhatsApp?",
		taskTitle2: "Shared videos on WhatsApp",
		taskName: "sharedVideosOnWA",
		taskDescription: "Shared Reels on WhatsApp Status and with individuals",
		dd: 0,
	},
	{
		taskId: 8,
		taskTitle: "Shared Dars Link on Status?",
		taskTitle2: "Shared Dars Link on Status",
		taskName: "sharedDarsLink",
		taskDescription:
			"Shared YouTube Link of Dars e Quran Video on WhatsApp Status.",
		dd: 0,
	},
];

export const dailyTasksWithTwitter = [
	{
		taskId: 1,
		taskTitle: "Shared Videos of Allama in FB Groups?",
		taskTitle2: "Shared Videos of Allama in dd FB Groups",
		taskName: "shareVideosOfAllama",
		taskDescription:
			"Videos of Allama Shared in at least 2 district-specific FB Groups",
		dd: 1,
	},
	{
		taskId: 2,
		taskTitle: "Shared Videos of Allama in FB Groups?",
		taskTitle2: "Shared Videos of Allama in dd2 FB Groups",
		taskName: "shareVideosOfAllamaD2",
		taskDescription:
			"Videos of Allama Shared in at least 2 district-specific FB Groups",
		dd: 2,
	},
	{
		taskId: 3,
		taskTitle: "Liked and Commented on FB Page?",
		taskTitle2: "Liked and Commented on FB Page",
		taskName: "likeAndComment",
		taskDescription: "Liked and Commented on a Post on Facebook Page of Allama",
		dd: 0,
	},
	{
		taskId: 4,
		taskTitle: "Mentioned Individuals in Comments?",
		taskTitle2: "Mentioned Individuals in Comments",
		taskName: "mentionRelatedIndividuals",
		taskDescription:
			"Mentioned related individuals in comments under Allama’s videos on FB & YT",
		dd: 0,
	},
	{
		taskId: 5,
		taskTitle: "Remixed Reels and Shorts?",
		taskTitle2: "Remixed Reels and Shorts with dd tagged as the location",
		taskName: "remixedReelsAndShorts",
		taskDescription:
			"Uploaded Shorts on YouTube & Insta by tagging location of assigned district",
		dd: 1,
	},
	{
		taskId: 6,
		taskTitle: "Remixed Reels and Shorts?",
		taskTitle2: "Remixed Reels and Shorts with dd2 tagged as the location",
		taskName: "remixedReelsAndShortsD2",
		taskDescription:
			"Uploaded Shorts on YouTube & Insta by tagging location of assigned district",
		dd: 2,
	},
	{
		taskId: 7,
		taskTitle: "Shared videos on WhatsApp?",
		taskTitle2: "Shared videos on WhatsApp",
		taskName: "sharedVideosOnWA",
		taskDescription: "Shared Reels on WhatsApp Status and with individuals",
		dd: 0,
	},
	{
		taskId: 8,
		taskTitle: "Shared Dars Link on Status?",
		taskTitle2: "Shared Dars Link on Status",
		taskName: "sharedDarsLink",
		taskDescription:
			"Shared YouTube Link of Dars e Quran Video on WhatsApp Status.",
		dd: 0,
	},
	{
		taskId: 9,
		taskTitle: "Retweet?",
		taskTitle2: "Retweet",
		taskName: "reTweet",
		taskDescription:
			"Repost Allama post on X.",
		dd: 0,
	},
];

export const weeklyTasks = [
	{
		taskId: 1,
		taskTitle: "Called Interested Leads?",
		taskTitle2: "Called Interested Leads of dd District?",
		taskName: "callInterestedLeads",
		taskDescription: "Calling interested Leads of assigned district (Weekends)",
		dd: 1,
	},
	{
		taskId: 2,
		taskTitle: "Called Interested Leads?",
		taskTitle2: "Called Interested Leads of dd2 District?",
		taskName: "callInterestedLeadsD2",
		taskDescription: "Calling interested Leads of assigned district (Weekends)",
		dd: 2,
	},
	{
		taskId: 3,
		taskTitle: "Attended Supervisor Meeting?",
		taskTitle2: "Attended Supervisor Meeting",
		taskName: "attendMeetingWithSupervisor",
		taskDescription: "Attended Meeting with Supervisor (Weekends)",
		dd: 0,
	},
	{
		taskId: 4,
		taskTitle: "Promoted Weekly Dars e Quran?",
		taskTitle2: "Promoted Weekly Dars e Quran in dd District",
		taskName: "promotedWeeklyDars",
		taskDescription: "Promoted Weekly Dars e Quran Sessions of Allama Sb",
		dd: 1,
	},
	{
		taskId: 5,
		taskTitle: "Promoted Weekly Dars e Quran?",
		taskTitle2: "Promoted Weekly Dars e Quran in dd2 District",
		taskName: "promotedWeeklyDarsD2",
		taskDescription: "Promoted Weekly Dars e Quran Sessions of Allama Sb",
		dd: 2,
	},
];

export const oneTimeTasks = [
	{
		taskId: 1,
		taskTitle: "Set Reminders?",
		taskTitle2: "Set Reminders",
		taskName: "setReminders",
		taskDescription:
			"Schedule daily reminders on your phone to stay engaged with the project.",
		dd: 0,
	},
	{
		taskId: 2,
		taskTitle: "Join FB Groups?",
		taskTitle2: "Join dd FB Groups",
		taskName: "joinFBGroups",
		taskDescription: "Join Facebook groups specific to your assigned district.",
		dd: 1,
	},
	{
		taskId: 3,
		taskTitle: "Join FB Groups?",
		taskTitle2: "Join dd2 FB Groups",
		taskName: "joinFBGroupsD2",
		taskDescription: "Join Facebook groups specific to your assigned district.",
		dd: 2,
	},
	{
		taskId: 4,
		taskTitle: "District Research?",
		taskTitle2: "dd District Research",
		taskName: "districtResearch",
		taskDescription:
			"Research important district details, influencers, and notable figures.",
		dd: 1,
	},
	{
		taskId: 5,
		taskTitle: "District Research?",
		taskTitle2: "dd2 District Research",
		taskName: "districtResearchD2",
		taskDescription:
			"Research important district details, influencers, and notable figures.",
		dd: 2,
	},
	{
		taskId: 6,
		taskTitle: "Add 5000 FB Friends?",
		taskTitle2: "Add 5000 FB Friends",
		taskName: "add5000FBFriend",
		taskDescription:
			"Build a network by adding up to 5,000 friends from the assigned district on Facebook.",
		dd: 0,
	},
	{
		taskId: 7,
		taskTitle: "Create Social Media Profiles?",
		taskTitle2: "Create Social Media Profiles",
		taskName: "createSocialMediaProfiles",
		taskDescription: "Create Social Media Profiles .",
		dd: 0,
	},
	{
		taskId: 8,
		taskTitle: "Complete Training Sessions?",
		taskTitle2: "Complete Training Sessions",
		taskName: "trainingSessions",
		taskDescription:
			"Complete your training sessions on Facebook, Instagram & YouTube with the provided trainers list.",
		dd: 0,
	},
	{
		taskId: 9,
		taskTitle: "Find & Share Referrals?",
		taskTitle2: "Find & Share Referrals",
		taskName: "findAndShareReferrals",
		taskDescription:
			"Collect referrals from your co-workers by asking in telegram groups of WORK and share referrals with the WORKers if you have any district that can be useful for lead generations and visits.",
		dd: 0,
	},
	{
		taskId: 10,
		taskTitle: "Try ChatGPT",
		taskTitle2: "Try ChatGPT",
		taskName: "learnChatgpt",
		taskDescription:
			"Learn ChatGPT from the course provided in the Telegram group.",
		dd: 0,
	},
];

export const monthlyOfflineTasks = [
	{
		taskId: 1,
		taskTitle: "Visited Assigned Districts?",
		taskName: "visitDistrict",
		taskDescription: "Engage with leads by visiting assigned districts.",
	},
	{
		taskId: 2,
		taskTitle: "Distributed Flyers or Posters?",
		taskName: "distributeFlyer",
		taskDescription:
			"Distribute flyers or posters of the Quran TPI Course in Muslim areas.",
	},
	{
		taskId: 3,
		taskTitle: "Organized Meetup with Interested Leads?",
		taskName: "leadsMeetUp",
		taskDescription:
			"Coordinate and hold a meeting with leads who have shown interest in Allama's videos.",
	},
	{
		taskId: 4,
		taskTitle: "Workshops & Meetups",
		taskName: "workshopsAndMeetups",
		taskDescription:
			"Arrange workshops or meetups within the district to introduce new WORKers and build a local presence.",
	},
];

export const monthlyOnlineTasks = [
	{
		taskId: 5,
		taskTitle: "Collect Testimonials?",
		taskName: "collectTestimonail",
		taskDescription:
			"Gather short testimonials from people in the district who have benefitted from Allama’s videos.",
	},
	{
		taskId: 6,
		taskTitle: "Zoom Meetings with Interested Leads?",
		taskName: "leadsZoomMeeting",
		taskDescription:
			"Conduct Zoom meetings with interested leads to discuss opportunities.",
	},
	{
		taskId: 7,
		taskTitle: "Influencer Outreach?",
		taskName: "influencerOutreach",
		taskDescription:
			"Identify local influencers or district leaders and establish connections for credibility.",
	},
];

export const dailyTaskContentSheet = [
	{
		taskTitle: "Share Video of Allama in Facebook Muslim Groups",
		isCaptionTask: true,
		district2Task: false,
		taskData: {
			caption: "shareMuslimVideosOfAllamaCaption",
			link: "shareMuslimVideosOfAllamaLink",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Share Video of Allama in Facebook General Groups",
		isCaptionTask: true,
		district2Task: false,
		taskData: {
			caption: "shareNonMuslimVideosOfAllamaCaption",
			link: "shareNonMuslimVideosOfAllamaLink",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Liked and Commented on FB Page",
		taskLink: "likeAndCommentOnFBPageLink",
		isCaptionTask: false,
		district2Task: false,
		isLinkTask: true
	},
	{
		taskTitle: "Mentioned Individuals in Comments",
		taskLink: "mentionIndividualsInCommentsLink",
		isCaptionTask: false,
		district2Task: false,
		isLinkTask: true
	},
	{
		taskTitle: "Remix Reel on Facebook",
		isCaptionTask: true,
		district2Task: false,
		taskData: {
			caption: "remixFBCaption",
			link: "remixFBLink",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Remix Reel on Facebook (District 2)",
		isCaptionTask: true,
		district2Task: true,
		taskData: {
			caption: "remixFBCaptionD2",
			link: "remixFBLinkD2",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Remix Reel on Insta",
		isCaptionTask: true,
		district2Task: false,
		taskData: {
			caption: "remixInstaCaption",
			link: "remixInstaLink",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Remix Reel on Insta (District 2)",
		isCaptionTask: true,
		district2Task: true,
		taskData: {
			caption: "remixInstaCaptionD2",
			link: "remixInstaLinkD2",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Remix Shorts on YouTube",
		isCaptionTask: true,
		district2Task: false,
		taskData: {
			caption: "remixYTCaption",
			link: "remixYTLink",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Remix Shorts on YouTube (District 2)",
		isCaptionTask: true,
		district2Task: true,
		taskData: {
			caption: "remixYTCaptionD2",
			link: "remixYTLinkD2",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Shared Videos on WhatsApp",
		isCaptionTask: true,
		district2Task: false,
		taskData: {
			caption: "sharedVideosOnWACaption",
			link: "sharedVideosOnWALink",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Shared Dars Link on Status",
		taskData: {
			caption: "sharedDarsLink"
		},
		isCaptionTask: true,
		district2Task: false,
		isLinkTask: false
	},
];

export const dailyTaskContentSheetWithTwitter = [
	{
		taskTitle: "Share Video of Allama in Facebook Muslim Groups",
		isCaptionTask: true,
		district2Task: false,
		taskData: {
			caption: "shareMuslimVideosOfAllamaCaption",
			link: "shareMuslimVideosOfAllamaLink",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Share Video of Allama in Facebook General Groups",
		isCaptionTask: true,
		district2Task: false,
		taskData: {
			caption: "shareNonMuslimVideosOfAllamaCaption",
			link: "shareNonMuslimVideosOfAllamaLink",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Liked and Commented on FB Page",
		taskLink: "likeAndCommentOnFBPageLink",
		isCaptionTask: false,
		district2Task: false,
		isLinkTask: true
	},
	{
		taskTitle: "Mentioned Individuals in Comments",
		taskLink: "mentionIndividualsInCommentsLink",
		isCaptionTask: false,
		district2Task: false,
		isLinkTask: true
	},
	{
		taskTitle: "Remix Reel on Facebook",
		isCaptionTask: true,
		district2Task: false,
		taskData: {
			caption: "remixFBCaption",
			link: "remixFBLink",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Remix Reel on Facebook (District 2)",
		isCaptionTask: true,
		district2Task: true,
		taskData: {
			caption: "remixFBCaptionD2",
			link: "remixFBLinkD2",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Remix Reel on Insta",
		isCaptionTask: true,
		district2Task: false,
		taskData: {
			caption: "remixInstaCaption",
			link: "remixInstaLink",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Remix Reel on Insta (District 2)",
		isCaptionTask: true,
		district2Task: true,
		taskData: {
			caption: "remixInstaCaptionD2",
			link: "remixInstaLinkD2",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Remix Shorts on YouTube",
		isCaptionTask: true,
		district2Task: false,
		taskData: {
			caption: "remixYTCaption",
			link: "remixYTLink",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Remix Shorts on YouTube (District 2)",
		isCaptionTask: true,
		district2Task: true,
		taskData: {
			caption: "remixYTCaptionD2",
			link: "remixYTLinkD2",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Shared Videos on WhatsApp",
		isCaptionTask: true,
		district2Task: false,
		taskData: {
			caption: "sharedVideosOnWACaption",
			link: "sharedVideosOnWALink",
		},
		isLinkTask: true
	},
	{
		taskTitle: "Shared Dars Link on Status",
		taskData: {
			caption: "sharedDarsLink"
		},
		isCaptionTask: true,
		district2Task: false,
		isLinkTask: false
	},
	{
		taskTitle: "Retweet on X",
		isCaptionTask: false,
		district2Task: false,
		taskLink: "reTweetLink",
		taskData: {
			caption: "reTweetCaption",
			link: "reTweetLink",
		},
		isLinkTask: true
	},
];
