import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import React from 'react'
import { headingBlue, maroon, pureWhite, red } from '../assets/colors'
import { unAssignDistrict } from '../backend/backendApis'
import HttpService from '../backend/requestApis'
import { useNavigate } from 'react-router-dom'
import { commonErrorStyleText } from '../utils/commonCSS'

const MemberEditDialog = (props) => {

    const [firstDistrictCheckBox, setFirstDistrictCheckBox] = React.useState(false)
    const [secondDistrictCheckBox, setSecondDistrictCheckBox] = React.useState(false)
    const [isUpdate, setIsUpdate] = React.useState(false)
    const [isError, setIsError] = React.useState('')
    const [isLoader, setIsLoader] = React.useState(false)

    const navigate = useNavigate()

    const handleUpdate = () => {
        if(!firstDistrictCheckBox && !secondDistrictCheckBox){
            setIsError('Choose atleast one district to update')
        } else {
            setIsUpdate(true)
        }
    }

    const handleConfirm = () => {
        try{
            let options = {
                url: unAssignDistrict,
                method: 'POST',
                payload: {
                    contactNumber: props?.data?.contactNumber,
                    gender: props?.data?.gender,
                    selectedDistrict: props?.data?.selectedDistrict,
                    selectedChapter: props?.data?.selectedChapter,
                    secondSelectedDistrict: props?.data?.secondSelectedDistrict,
                    secondSelectedChapter: props?.data?.secondSelectedChapter
                },
                headers: {headers: {
                    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
                }}
            }
            if( firstDistrictCheckBox && secondDistrictCheckBox){
                options['payload']['noOfDistrictsToRemove'] = 2
            } else {
                options['payload']['noOfDistrictsToRemove'] = 1
                if( firstDistrictCheckBox){
                    options['payload']['districtToRemove'] = 1
                } else {
                    options['payload']['districtToRemove'] = 2
                }
            }

            HttpService(options).then(() => {
                console.log("District Unassigned Successfully")
                props.updateDialog(false, {}, true)
            }).catch(err => {
                console.log("Error in unAssigning District: ", err)
                if(err?.status === 403){
                    localStorage.clear()
                    // setIsLoader(false)
                    navigate('/Login')
                }
            })

        } catch(err){
            console.log('Error in unAssigning District: ', err)

        }
    }

    React.useEffect(() => {
        setFirstDistrictCheckBox(false)
        setSecondDistrictCheckBox(false)
        setIsUpdate(false)
        setIsError('')
    },[props])

    var titleStyle={
        backgroundColor: headingBlue,
        color: 'white',
        fontSize:'1rem',
        display: "flex",
        alignItems: 'center'
      }
  return (
    <>
        <Dialog maxWidth = 'xs' fullWidth open={props.dialogStatus} onClose={() => props.updateDialog(false)}>
            <DialogTitle sx={{height: "1.8rem", padding: "0.4rem 0rem 0.4rem 1rem"  }}  style={titleStyle}>
                Edit Member
            </DialogTitle>
            <DialogContent style={{marginTop: '1rem'}}>
                <>
                    {!isUpdate &&
                        <Box style={{color: maroon}}>
                            <Grid container spacing={1}>
                                <Grid item xs={1.25} sm={1.25}>
                                    <Checkbox sx={{color: headingBlue, padding: '0rem 0.5rem 0rem 0.5rem'}} 
                                        checked={firstDistrictCheckBox}
                                        onChange={() => {
                                            setFirstDistrictCheckBox(!firstDistrictCheckBox)
                                        }}
                                    ></Checkbox>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <Typography >{props?.data?.selectedDistrict}</Typography>
                                </Grid>
                                <Grid item xs={5} sm={5}>
                                    <Typography>{props?.data?.selectedChapter}</Typography>
                                </Grid>

                            </Grid>
                            {
                                props?.data?.secondSelectedDistrict &&
                                props?.data?.secondSelectedChapter  &&
                                <>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1.25} sm={1.25}>
                                            <Checkbox sx={{color: headingBlue, padding: '0rem 0.5rem 0rem 0.5rem'}}
                                                checked={secondDistrictCheckBox}
                                                onChange={() => {
                                                    setSecondDistrictCheckBox(!secondDistrictCheckBox)
                                                }}
                                            ></Checkbox>
                                        </Grid>
                                        <Grid item xs={4} sm={4}>
                                            <Typography >{props?.data?.secondSelectedDistrict}</Typography>
                                        </Grid>
                                        <Grid item xs={5} sm={5}>
                                            <Typography>{props?.data?.secondSelectedChapter}</Typography>
                                        </Grid>

                                    </Grid>
                                </>
                            }
                            <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '1rem 0rem 0rem 0rem'}}>
                                <Typography style={commonErrorStyleText}>
                                    {isError}
                                </Typography>
                                <Button style={{marginTop: '0.3rem', padding: '0.1rem 0.4rem 0.1rem 0.4rem', border: `1.5px solid ${headingBlue}`, backgroundColor: headingBlue, color: pureWhite, fontWeight: 500}}
                                    onClick={() => {
                                        handleUpdate()
                                    }}
                                >
                                    update
                                </Button>
                            </Box>
                            
                        </Box>
                    }
                    {
                        isUpdate &&
                        <Box style={{color:red, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                            <Typography>Are You sure You want to unassign </Typography>
                                {firstDistrictCheckBox &&  
                                    <>
                                    <Box style={{width: '100%'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4} sm={4}>
                                                <Typography style={{textAlign: 'left', color:headingBlue}}>{props?.data?.selectedDistrict}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} sm={8} style={{display: 'flex', justifyContent: 'left'}}>
                                                <Typography style={{textAlign: 'left', color:headingBlue}}>
                                                    {props?.data?.selectedChapter}
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    </Box>
                                        
                                    </>
                                }
                                {secondDistrictCheckBox &&  
                                    <>
                                        <Box style={{width: '100%'}}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={4} sm={4}>
                                                    <Typography style={{textAlign: 'left', color:headingBlue}}>{props?.data?.secondSelectedDistrict}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8} sm={8} style={{display: 'flex', justifyContent: 'left'}}>
                                                    <Typography style={{textAlign: 'left', color:headingBlue}}>
                                                        {props?.data?.secondSelectedChapter}
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </Box>
                                    </>
                                }
                            <Box>
                                <Box style={{display: 'flex', justifyContent: 'center', padding: '1rem 0rem 0rem 0rem'}}>
                                <Button style={{padding: '0.1rem 0.4rem 0.1rem 0.4rem', border: `1.5px solid ${red}`, backgroundColor: red, color: pureWhite, fontWeight: 500}}
                                        onClick={() => {
                                            setIsUpdate(false)
                                            props.updateDialog(false)
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button style={{marginLeft: '0.3rem',padding: '0.1rem 0.4rem 0.1rem 0.4rem', border: `1.5px solid ${headingBlue}`, backgroundColor: headingBlue, color: pureWhite, fontWeight: 500}}
                                        onClick={() => {
                                            handleConfirm()                                        }}
                                    >
                                        Confirm
                                    </Button>
                                </Box>
                            </Box>

                        </Box>
                    }
                </>

            </DialogContent>

        </Dialog>
    </>
  )
}

export default MemberEditDialog