import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	green,
	headingBlue,
	lightGrey,
	maroon,
	mediumGrey,
	pureWhite,
	red,
	yellowish,
} from "../assets/colors";
import { buttonStyle } from "./formStyle";
import HttpService from "../backend/requestApis";
import { getDistrictsList, getStatesList, updateReferalLeadStatus } from "../backend/backendApis";
import CustomRadioButton from "./CustomRadioButtons";
import CustomLeadsRating from "./CustomLeadsRating";
import { State, City }  from 'country-state-city';
import CustomDatePicker from "./CustomDatePicker";
import CustomDropDown from "./CustomDropDown";
import { genderList } from "../utils/constants";

const ConfirmationWindow = ({
	windowStatus,
	updateWindowStatus,
	isMobile,
	setIsLoading,
	setApiError,
	lead,
}) => {
	const [selectedCallStatus, setSelectedCallStatus] = useState(lead.leadStatus);
	const [selectedStatus, setSelectedStatus] = useState(lead.leadStatus)

	const [gender, setGender] = useState(lead.gender)

	const [introductionOfAllama, setIntroductionOfAllama] = useState(lead.introductionOfAllama)
	const [importanceOfDarseQuran, setImportanceOfDarseQuran] = useState(lead.importanceOfDarseQuran)
	const [QuranTPICourse, setQuranTPICourse] = useState(lead.QuranTPICourse)
	const [addedToWhatsApp, setAddedToWhatsApp] = useState(lead.addedToWhatsApp)
	const [explained2026, setExplained2026] = useState(lead.explained2026)
	const [leadResponseValue, setLeadResponseValue] = useState(lead.leadResponse ? lead.leadResponse : 0)
	const [stateList, setStateList] = useState([])
	const [selectedState, setSelectedState] = useState(lead.chapter)
	const [citiesList, setCitiesList] = useState([])
	const [seletcedCity, setSelectedCity] = useState(lead.district)

	const handleReset = () => {
		setSelectedStatus(null)
		setSelectedCallStatus(null)
		setIntroductionOfAllama(false)
		setImportanceOfDarseQuran(false)
		setQuranTPICourse(false)
		setAddedToWhatsApp(false)
		setExplained2026(false)
		setLeadResponseValue(0)
	}

	const updateSelectedState = (state) => {
		if( state){
			setIsLoading(true)
			setSelectedState(state)
			let options = {
				url: getDistrictsList,
				method: 'GET',
				params: {
					state: state
				}
			}
			HttpService(options).then((response) => {
				if( response?.data?.status === 1){
					console.log("responsw: ", response)
					let cities = response?.data?.data 
					console.log("cities: ", cities)
					setCitiesList(cities)
					setIsLoading(false)
				} else {
					setApiError('Error in fetching cities List. Please Contact support.')
					setIsLoading(false)
				}
			})
		}
	}

	const updatedSelectedCity = (city) => {
		if( city){
			setSelectedCity(city)
		}
	}

	async function handleAcceptedRejected(updatingCallStatus=false) {
		try {
			if( updatingCallStatus && (selectedCallStatus === 'followUp' || selectedCallStatus === 'wrongDistrict')){
				setSelectedStatus(() => selectedCallStatus)
			} else {
			setIsLoading(true);
			let token = await localStorage.getItem("token");
			let options = {
				url: updateReferalLeadStatus,
				method: "PUT",
				payload: {
					_id: lead._id,
					leadStatus: selectedCallStatus,
					introductionOfAllama: introductionOfAllama,
					importanceOfDarseQuran: importanceOfDarseQuran,
					QuranTPICourse: QuranTPICourse,
					addedToWhatsApp: addedToWhatsApp,
					explained2026: explained2026,
					leadResponse: leadResponseValue
				},
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			};
			if( selectedCallStatus === 'wrongDistrict'){
				options['payload']['gender'] = gender
				options['payload']['district'] = seletcedCity
				options['payload']['chapter'] = selectedState
			}
			HttpService(options)
				.then((response) => {
					if (response.data.status === 1) {
						setIsLoading(false);
						updateWindowStatus(true);
					} else {
						setApiError("Error in submitting. Please Contact Support");
						setIsLoading(false);
						updateWindowStatus(true);
					}
				})
				.catch((err) => {
					console.log("Error in submiting form.", err);
					setApiError("Error in submitting. Please Contact Support");
					setIsLoading(false);
					updateWindowStatus(true);
				});
			}
			// setIsFormSubmitted(true);
		} catch (err) {
			console.log("Error in submitting form.", err);
			setApiError("Error in submitting. Please Contact Support");
			setIsLoading(false);
			updateWindowStatus(true);
		}
		
	}

	function handleRadio(e) {
		setSelectedCallStatus(e.target.value);
	}

	useEffect(() => {
		if( selectedStatus === 'wrongDistrict'){
			setIsLoading(true)
			let options = {
				url: getStatesList,
				method: 'GET'
			}

			HttpService(options).then(async (response) => {
				if( response?.data?.status === 1){
					let state = response?.data?.data
					setStateList(state)
					let selState = state.filter(st => {
						return (st  === selectedState)
					})
					await updateSelectedState(selState[0])
					setIsLoading(false)	
				} else {
					setApiError('Error in fetching states List. Please Contact support.')
					setIsLoading(false)
				}
			})
		}
	}, [selectedStatus])

	return (
		<Dialog
			maxWidth="xs"
			fullWidth
			open={windowStatus}
			onClose={() => updateWindowStatus()}
		>
			<DialogTitle
				sx={{ height: "1.8rem", padding: "0.3rem 1rem 0.3rem 1rem" }}
				style={{
					backgroundColor: headingBlue,
					color: "white",
					// textAlign: "center",
					fontSize: "1.1rem",
					fontWeight: "bold",
				}}
			>
				Lead Details
			</DialogTitle>
			<DialogContent
				style={{
					textAlign: "center",
					marginTop: "0.7rem",
				}}
			>
				<Box
					sx={{
						display: "grid",
						gridTemplateColumns: "2.1fr 0.8fr 1.6fr 1.5fr",
						gap: "0.5rem",
						padding: "0.2rem 0rem 0rem 0rem",
						color: maroon,
						alignItems: 'center'
					}}
				>
					<Typography sx={{ textAlign: 'left', fontSize: isMobile ? "0.8rem" : "0.9rem" }}>
						{lead.name}
					</Typography>
					<Typography sx={{textAlign: 'center',fontSize: isMobile ? "0.8rem" : "0.9rem" }}>
						{lead.age ? `${lead.age} Y` : '-'}
					</Typography>
					<Typography sx={{textAlign: 'center', fontSize: isMobile ? "0.8rem" : "0.9rem" }}>
						{lead.district}
					</Typography>
					<Typography sx={{textAlign: 'right', fontSize: isMobile ? "0.8rem" : "0.9rem",  }}  component='a' href={`tel:${lead.contactNumber}`}>
						{lead.contactNumber}
					</Typography>
				</Box>
				{!selectedStatus || selectedStatus === 'wrongNumber' || selectedStatus === 'notReachable'? 
				<>
				<Box sx={{ textAlign: "left", marginTop: "0.7rem" }}>
					<Typography style={{color: maroon}}>Call Initiated</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							margin: 0,
							padding: 0,
							color: headingBlue
						}}
					>
						<FormControl>
							<RadioGroup
								value={selectedCallStatus}
								onChange={handleRadio}
								sx={{ flexDirection: "column", 
									padding: '0rem'
								}}
							>
								<FormControlLabel
									value="wrongNumber"
									control={<Radio sx={{ padding:"0.2rem 0rem 0rem 0.5rem", transform: "scale(0.8)" }} />}
									label={
										<span style={{ fontSize: "0.9rem" }}>Wrong Number</span>
									}
									style={{ margin: 0, padding: "0rem 0rem 0rem 0rem"  }}
									// style={{border: '1px solid' }}
								/>
								<FormControlLabel
									value="wrongDistrict"
									control={<Radio sx={{padding:"0.2rem 0rem 0rem 0.5rem", transform: "scale(0.8)" }} />}
									label={
										<span style={{ fontSize: "0.9rem" }}>Wrong Information</span>
									}
									style={{ margin: 0, padding: 0 }}
								/>
								<FormControlLabel
									value="notReachable"
									control={<Radio sx={{padding:"0.2rem 0rem 0rem 0.5rem", transform: "scale(0.8)" }} />}
									label={
										<span style={{ fontSize: "0.9rem" }}>Not reachable</span>
									}
									style={{ margin: 0, padding: 0 }}
								/>
								<FormControlLabel
									value="followUp"
									control={<Radio sx={{padding:"0.2rem 0rem 0rem 0.5rem", transform: "scale(0.8)" }} />}
									label={<span style={{ fontSize: "0.9rem" }}>Follow Up</span>}
									style={{ margin: 0, padding: 0 }}
								/>
							</RadioGroup>
						</FormControl>
					</Box>
				</Box>

				<Box
					sx={{
						display: "flex",
						alignItems: 'center',
						justifyContent: "center",
						padding: '0.5rem 0rem 0rem 0rem'
					}}
				>
					<Button
						sx={{
							border: "1px solid",
							borderColor: headingBlue,
							backgroundColor: headingBlue,
							color: pureWhite,
							fontSize: "0.8rem",
						}}
						onClick={() => handleAcceptedRejected(true)}
					>
						Update Lead
					</Button>
					{/* <Button
						sx={{
							border: "1px solid",
							borderColor: headingBlue,
							backgroundColor: red,
							color: pureWhite,
						}}
						onClick={() => handleAcceptedRejected(rowIndex, "Rejected")}
					>
						Close Lead
					</Button> */}
				</Box>
				</> :
				selectedStatus === 'wrongDistrict' ? 
					<>
						<Box style={{display: 'flex', flexDirection: 'row', padding: "1rem 0rem 0.5rem 0rem"}}>
							<Grid container spacing={1}>
								<Grid item xs={6} sm={6}>
									<Typography style={{display: 'flex', justifyContent: 'left', color: headingBlue, fontSize: '0.9rem', marginLeft: '0.5rem'}}>Gender</Typography>
									<CustomDropDown list={genderList} value={gender} setFunc={setGender}></CustomDropDown>
								</Grid>
								<Grid item xs={6} sm={6}>

								</Grid>
								<Grid item xs={6} sm={6}>
									<Typography style={{display: 'flex', justifyContent: 'left', color: headingBlue, fontSize: '0.9rem', marginLeft: '0.5rem'}}>State</Typography>
									<CustomDropDown list={stateList} value={selectedState} setFunc={updateSelectedState}></CustomDropDown>
								</Grid>
								<Grid item xs={6} sm={6}>
								<Typography style={{display: 'flex', justifyContent: 'left', color: headingBlue, fontSize: '0.9rem', marginLeft: '0.5rem'}}>City</Typography>
									<CustomDropDown list={citiesList} value={seletcedCity} setFunc={updatedSelectedCity}></CustomDropDown>
								</Grid>

							</Grid>
						</Box>
						<Box
						sx={{
							display: "flex",
							alignItems: 'center',
							justifyContent: "center",
							padding: '0.5rem 0rem 0rem 0rem'
						}}
					>
						<Button
							sx={{
								border: "1px solid",
								borderColor: red,
								backgroundColor: red,
								color: pureWhite,
								fontSize: "0.8rem",
								marginRight: "0.5rem"
							}}
							onClick={() => handleReset()}>
							Previous
						</Button>
						<Button
							sx={{
								border: "1px solid",
								borderColor: headingBlue,
								backgroundColor: headingBlue,
								color: pureWhite,
								fontSize: "0.8rem",
							}}
							onClick={() => handleAcceptedRejected()}
						>
							Update Lead
						</Button>
						</Box>
					</> :
					<>
					<Box style={{padding: "1rem 0rem 0.5rem 0rem"}}>
						{selectComponent('Introduction of Allama', introductionOfAllama, setIntroductionOfAllama)}
						{selectComponent('Importance of Dars-e-Quran', importanceOfDarseQuran, setImportanceOfDarseQuran)}
						{selectComponent('Quran TPI Course', QuranTPICourse, setQuranTPICourse)}
						{selectComponent('Added to WhatsApp Group', addedToWhatsApp, setAddedToWhatsApp)}
						{selectComponent('Explained 2026 Concept', explained2026, setExplained2026)}
					</Box>
					<Divider></Divider>
					<Box sx={{
								display: "flex",
								justifyContent: 'center',
								alignItems: 'center',
								margin: 0,
								padding: "0.4rem",
								color: headingBlue,
								// height: '30vh',
								// overflow: 'auto'
							}}>
						<CustomLeadsRating value={leadResponseValue} valueFunc={setLeadResponseValue}></CustomLeadsRating>
					</Box>
					<Box
						sx={{
							display: "flex",
							alignItems: 'center',
							justifyContent: "center",
							padding: '0.5rem 0rem 0rem 0rem'
						}}
					>
						<Button
							sx={{
								border: "1px solid",
								borderColor: red,
								backgroundColor: red,
								color: pureWhite,
								fontSize: "0.8rem",
								marginRight: "0.5rem"
							}}
							onClick={() => handleReset()}>
							Previous
						</Button>
						<Button
							sx={{
								border: "1px solid",
								borderColor: headingBlue,
								backgroundColor: headingBlue,
								color: pureWhite,
								fontSize: "0.8rem",
							}}
							onClick={() => handleAcceptedRejected()}
						>
							Update Lead
						</Button>
						{/* <Button
							sx={{
								border: "1px solid",
								borderColor: headingBlue,
								backgroundColor: red,
								color: pureWhite,
							}}
							onClick={() => handleAcceptedRejected(rowIndex, "Rejected")}
						>
							Close Lead
						</Button> */}
					</Box>
					</>
				}
			</DialogContent>
		</Dialog>
	);
};

function selectComponent(title, value, setFunc){
    return(
        <>
            <Box style={{display: 'flex', flexDirection: 'row'}}>
                <Checkbox style={{ padding: '0rem 0.1rem 0rem 0.1rem'}}
                    checked={value}
                    onChange={() => {
                        setFunc(!value)
                    }}
                >

                </Checkbox>
                <Typography sx={{fontSize: '0.9rem',color: headingBlue}}>{title}</Typography>
            </Box>
            
        </>
    )
}

export default ConfirmationWindow;
