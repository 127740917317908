import { Box, Button, Checkbox, Menu, Popover, Typography } from '@mui/material'
import React from 'react'
import { headingBlue, maroon, pureWhite, textColor } from '../assets/colors';
import CustomDropDown from './CustomDropDown';
import { getAllSupervisors, getSupervisorsList } from '../backend/backendApis';
import HttpService from '../backend/requestApis';
import { CheckBox } from '@mui/icons-material';

const CustomMenu = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [supervisorsList, setSupervisorsList] = React.useState([])
    const [notInitiated, setNotInitiated] = React.useState(false)
    const [followUp, setFollowUp] = React.useState(false)
    const [wrongNumber, setWrongNumber] = React.useState(false)
    const [notreachable, setNotReachable] = React.useState(false)
    const [closed, setClosed] = React.useState(false)

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    React.useEffect(() => {
        try{
            let options = {
                url: getAllSupervisors,
                method: 'GET',
                headers: {
                    Authorization: localStorage.getItem("token")
                        ? `Bearer ${localStorage.getItem("token")}`
                        : null,
                },
            }
            HttpService(options).then(data => {
                console.log("data: ", data)

            }).catch(err => {
                console.log('Error in fetching supervisors list: ', err)
            })
        } catch(err){
            console.log("Error in fetching supervisors list", err)
        }
    }, [])

    var typographyStyle= {
        fontSize: '1rem',
        color: maroon,
        padding: "0.1rem"
    }

    return (
        <div style={{padding: '0.2rem 0.1rem 0.2rem 1.5rem'}}>
            <Button
                variant='contained'
                aria-describedby="filter-button"
                // aria-controls={open ? 'basic-menu' : undefined}
                // aria-haspopup="true"
                // aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    backgroundColor:pureWhite,
                    color: headingBlue,
                    border: `1px solid ${headingBlue}`,
                    padding: "0.2rem 0.5rem 0.2rem 0.5rem",
                    
                }}
            >
                Filters

            </Button>
            <Popover
                id='filter-button'
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
            >
                <Box p={18} sx={{ padding: '1rem'}}>
                    {props.designation === 'admin' && 
                        <>
                            <Typography style={typographyStyle}>
                                Lead Status
                            </Typography>
                            <Box style={{padding: '0rem 0rem 0rem 0.3rem'}}>
                                {selectComponent('Not Initiated', notInitiated, setNotInitiated)}
                                {selectComponent('Follow Up', followUp, setFollowUp)}
                                {selectComponent('Wrong Number', wrongNumber, setWrongNumber)}
                                {selectComponent('Not Reachable', notreachable, setNotReachable)}
                                {selectComponent('Closed', closed, setClosed)}
                            </Box>
                            <Typography style={{...typographyStyle, paddingTop: '0.5rem'}}>
                                Lead Response
                            </Typography>
                            <Box style={{padding: '0rem 0rem 0rem 0.3rem'}}>
                                {selectComponent('VIP Contact')}
                                {selectComponent('Potential WORKer')}
                                {selectComponent('Interested in Knowledge')}
                                {selectComponent('Low Response')}
                                {selectComponent('Not Interested')}
                                {selectComponent('Time Waste')}
                                {selectComponent('Anti Allama')}
                            </Box>
                            
                        </>
                    }

                </Box>

            </Popover>
        </div>

    )
}

function selectComponent(title, value, setFunc){
    return(
        <>
            <Box style={{display: 'flex', flexDirection: 'row'}}>
                <Checkbox style={{ padding: '0rem 0.1rem 0rem 0.1rem'}}
                    checked={value}
                    onChange={() => {
                        setFunc(!value)
                    }}
                >

                </Checkbox>
                <Typography sx={{fontSize: '0.9rem',color: headingBlue}}>{title}</Typography>
            </Box>
            
        </>
    )
}

export default CustomMenu