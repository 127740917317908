import { Button, Paper, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { monthlyTaskApi } from "../backend/backendApis";
import {
	monthlyOfflineTasks,
	monthlyOnlineTasks,
} from "../data/taskCollections";
import ConfirmationWindow from "./ConfirmationWindow";
import {
	buttonStyle,
	containerStyle,
	imgContainer,
	imgStyle,
} from "./formStyle";
import InfoButton from "./InfoButton";
import MonthlyOfflineTask from "./MonthlyOfflineTask";
import MonthlyOnlineTasks from "./MonthlyOnlineTasks";
import ReportFormSubmitMessage from "./ReportFormSubmitMessage";
import HeaderBar from "./HeaderBar";
import CustomDropDown from "./CustomDropDown";
import { monthDropDown } from "../utils/constants";
import BackArrow from "./BackArrow";

const MonthlyTaskReportForm = () => {
	const [isButtonSubmit, setIsButtonSubmit] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [isOpenWindow, setIsOpenWindow] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState("Month 1");
	const [options, setOptions] = useState({});

	const isMobile = useMediaQuery("(max-width: 600px)");

	const [offlineCheckboxStates, setOfflineCheckboxStates] = useState(
		monthlyOfflineTasks.reduce(
			(acc, task) => ({ ...acc, [task.taskName]: false }),
			{}
		)
	);
	const [onlineCheckboxStates, setOnlineCheckboxStates] = useState(
		monthlyOnlineTasks.reduce(
			(acc, task) => ({ ...acc, [task.taskName]: false }),
			{}
		)
	);

	const getOfflineCheckedTasks = () => {
		return Object.keys(offlineCheckboxStates).filter(
			(taskName) => offlineCheckboxStates[taskName]
		);
	};
	const getOnlineCheckedTasks = () => {
		return Object.keys(onlineCheckboxStates).filter(
			(taskName) => onlineCheckboxStates[taskName]
		);
	};

	const numOfCheckedTasks =
		getOfflineCheckedTasks().length + getOnlineCheckedTasks().length;

	const isAllTasksCompleted =
		numOfCheckedTasks ===
		monthlyOfflineTasks.length + monthlyOnlineTasks.length;

	const checkboxStates = Object.assign(
		{},
		onlineCheckboxStates,
		offlineCheckboxStates
	);

	function closeWindow() {
		setIsOpenWindow(false);
		setIsButtonSubmit(false);
	}

	function updateMonth(month) {
		setSelectedMonth(month);
	}

	function handleSubmit() {
		setIsOpenWindow(true);
		setIsButtonSubmit(true);

		if (numOfCheckedTasks === 0) return;

		checkboxStates.contactNumber = "8810572569";
		checkboxStates.monthNumber = Number(selectedMonth.split(" ").at(1));
		console.log(checkboxStates);
		let apiOptions = {
			url: monthlyTaskApi,
			method: "POST",
			payload: checkboxStates,
		};

		setOptions(apiOptions);
	}

	return (
		<InfoButton>
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					overflow: "auto",
					padding: 0,
				}}
			>
				<HeaderBar isReportForm={true} />
				<div style={containerStyle}>
					<div style={imgContainer}>
						<img
							src={require("../assets/images/WORK_logo.png")}
							alt="Logo"
							style={imgStyle}
						></img>
					</div>
					<BackArrow />
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "0rem 0.2rem",
						}}
					>
						<h3>Monthly Task</h3>
						{!isFormSubmitted && (
							<CustomDropDown
								width={isMobile ? "12rem" : "14rem"}
								regular={true}
								list={monthDropDown}
								value={selectedMonth}
								setFunc={updateMonth}
							></CustomDropDown>
						)}
					</div>
					<Paper
						style={{
							maxHeight: "55vh",
							overflowY: "auto",
							padding: "0rem",
							scrollbarWidth: "none",
						}}
					>
						{isFormSubmitted ? (
							<ReportFormSubmitMessage />
						) : (
							<div>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<MonthlyOfflineTask
										checkboxStates={offlineCheckboxStates}
										setCheckboxStates={setOfflineCheckboxStates}
										isButtonSubmit={isButtonSubmit}
									/>
									<MonthlyOnlineTasks
										checkboxStates={onlineCheckboxStates}
										setCheckboxStates={setOnlineCheckboxStates}
										isButtonSubmit={isButtonSubmit}
									/>
								</div>
								<div
									style={{
										textAlign: "center",
									}}
								>
									<Button
										style={{ ...buttonStyle, fontSize: "0.8rem" }}
										onClick={handleSubmit}
									>
										Submit
									</Button>
									{isButtonSubmit && (
										<ConfirmationWindow
											windowStatus={isOpenWindow}
											updateWindowStatus={closeWindow}
											isAllTasksCompleted={isAllTasksCompleted}
											isNoTaskChecked={numOfCheckedTasks === 0}
											setIsFormSubmitted={setIsFormSubmitted}
											options={options}
										/>
									)}
								</div>
							</div>
						)}
					</Paper>
				</div>
			</div>
		</InfoButton>
	);
};

export default MonthlyTaskReportForm;
