import { headingBlue, pureWhite, red } from "../assets/colors";

export var commonErrorStyleText = {
    color: red,
    marginTop: 1,
    fontSize: 11,
    marginLeft: 5,
    fontSize: "0.8rem"
}

export var buttonStyle = {
    color: pureWhite,
    backgroundColor: headingBlue,
    border: "1px solid #D9D9D9",
    marginRight: "0.3rem",
    fontFamily: "Helvetica",
    fontWeight: "500",
    fontSize: "0.9rem",
};

export var containerStyle = { 
    margin: "auto", 
    color: headingBlue, 
    fontSize: "1rem", 
    maxWidth: "25rem", 
    overflow:"auto", 
    padding: "1.25rem 1.25rem 0.2rem 1.25rem",
    marginTop: '2.3rem',
    // height: "85vh",
    // "&::-webkit-scrollbar":{
    //     display: 'none'
    // },
    // scrollbarWidth: "none"
}
export var imgContainer = {
    textAlign: 'center',
    padding: '0.4rem'
}

export var imgStyle = {
    width: '35%'
}

export var paperStyle= {
    color: headingBlue,
    padding: '0.4rem',
    boxShadow: '-0.05rem 0.05rem 0.1rem 0.1rem #D9D9D9'
}
export var typographyStyle= {
    fontSize: "0.9rem",
    textAlign: "left",
    padding: "0rem 0rem 0rem 1rem"
}

export var buttonContainer = {
    textAlign: 'center',
    padding: "0rem"
}
