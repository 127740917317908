import dayjs from "dayjs";
import { green, red, yellowish } from "../assets/colors";

export const tableOffsets = [
	{ name: 5 },
	{ name: 10 },
	{ name: 15 },
	{ name: 20 },
	{ name: 50 },
	{ name: 100 },
];

export const tableFilterOption = [
	{ name: "WORKer" },
	{ name: "Gender" },
	{ name: "Contact Number" },
	{ name: "District" },
	{ name: "Chapter" },
	{ name: "Selected District 1" },
	{ name: "Selected Chapter 1" },
	{ name: "Selected District 2" },
	{ name: "Selected Chapter 2" },
	{ name: "Alliance" },
	{ name: "Team Leader" },
	{ name: "Supervisor" },
];

export const reportTableFilterOption = [
	{ name: "WORKer" },
	{ name: "Contact Number" },
	{ name: "Alliance" },
	{ name: "Supervisor" },
	{ name: "Team Leader" },
];

export const leadTableFilterOption = [
	{ name: "Lead Name" },
	{ name: "Lead Contact" },
	{ name: "Phone Name" },
	{ name: "District" },
	{ name: "Chapter" },
];

export const leadsTaskList = [
	{
		taskTitle2: "Introduction of Allama",
		taskName: "introductionOfAllama"
	},
	{
		taskTitle2: "Importance of Dars-e-Quran",
		taskName: "importanceOfDarseQuran"
	},
	{
		taskTitle2: "Quran TPI Course",
		taskName: "QuranTPICourse"
	},
	{
		taskTitle2: "Added to WhatsApp Group",
		taskName: "addedToWhatsApp"
	},
	{
		taskTitle2: "Explained 2026",
		taskName: "explained2026"
	},
]


export const dashboardsList = [
	{ name: "One Time Task" },
	{ name: "Daily Task" },
	{ name: "Weekly Task" },
	// { name: "Monthly Task" },
	{ name: "Members" },
	{name: "Leads"}
];

export const weekDropDown = [
	{ name: "Week 1", displayName: "Week 1 / (25Nov - 1Dec)", weekNumber: 1},
	{ name: "Week 2", displayName: "Week 2 / (2Dec - 8Dec)", weekNumber: 2 },
	{ name: "Week 3", displayName: "Week 3 / (9Dec - 15Dec)", weekNumber: 3 },
	{ name: "Week 4", displayName: "Week 4 / (16Dec - 22Dec)", weekNumber: 4 },
	{ name: "Week 5", displayName: "Week 5 / (23Dec - 29Dec)", weekNumber: 5 },
	{ name: "Week 6", displayName: "Week 6 / (30Dec - 5Jan)", weekNumber: 6 },
	{ name: "Week 7", displayName: "Week 7 / (6Jan - 12Jan)", weekNumber: 7 },
	{ name: "Week 8", displayName: "Week 8 / (13Jan - 19Jan)", weekNumber: 8 },
	{ name: "Week 9", displayName: "Week 9 / (20Jan - 26Jan)", weekNumber: 9 },
	{ name: "Week 10", displayName: "Week 10 / (27Jan - 2Feb)", weekNumber: 10 },
	{ name: "Week 11", displayName: "Week 11 / (3Feb - 9Feb)", weekNumber: 11 },
	{ name: "Week 12", displayName: "Week 12 / (10Feb - 16Feb)", weekNumber: 12 },
	{ name: "Week 13", displayName: "Week 13 / (17Feb - 23Feb)", weekNumber: 13 },
	{ name: "Week 14", displayName: "Week 14 / (24Feb - 1March)", weekNumber: 14 },
];

export function getCurrentWeek(){
		let today = dayjs()
		if (today < dayjs('2024-12-02T00:00:00')) {
			console.log("Week :::::: ", weekDropDown[0])
			return weekDropDown[0]
		} else if (today < dayjs('2024-12-09T00:00:00')) {
			return weekDropDown[1]
		} else if (today < dayjs('2024-12-16T00:00:00')) {
			return weekDropDown[2]
		} else if (today < dayjs('2024-12-23T00:00:00')) {
			return weekDropDown[3]
		} else if (today < dayjs('2024-12-30T00:00:00')) {
			return weekDropDown[4]
		} else if (today < dayjs('2025-01-06T00:00:00')) {
			return weekDropDown[5]
		} else if (today < dayjs('2025-01-13T00:00:00')) {
			return weekDropDown[6]
		} else if (today < dayjs('2025-01-20T00:00:00')) {
			return weekDropDown[7]
		} else if (today < dayjs('2025-01-27T00:00:00')) {
			return weekDropDown[8]
		} else if (today < dayjs('2025-02-03T00:00:00')) {
			return weekDropDown[9]
		} else if (today < dayjs('2025-02-10T00:00:00')) {
			return weekDropDown[10]
		} else if (today < dayjs('2025-02-17T00:00:00')) {
			return weekDropDown[11]
		} else if (today < dayjs('2025-02-24T00:00:00')) {
			return weekDropDown[12]
		} else {
			return weekDropDown[13]
		}
}

export const monthDropDown = [
	{ name: "Month 1", displayName: "Month 1 / (18 Nov - 30 Nov)" },
	{ name: "Month 2", displayName: "Month 2 / (1 Dec - 31 Dec)" },
	{ name: "Month 3", displayName: "Month 3 / (1 Jan - 31 Jan)" },
	{ name: "Month 4", displayName: "Month 4 / (1 Feb - 1 Mar)" },
];

export const dashboardColumnMapping = {
	contactNumber: "Contact Number",
	name: "Name",
	gender: "Gender",
	district: "District",
	chapter: "Chapter",
	selectedDistrict: "Selected District",
	selectedChapter: "Selected State",
	secondSelectedDistrict: "Second Selected District",
	secondSelectedChapter: "Second Selected State",
	allianceName: "Alliance",
	leaderName: 'Leader',
	supervisorName: 'Supervisor',
}

export const leadsColumnMapping = {
	name: "Name",
	gender: "Gender",
	age: "Age",
	contactNumber: "Contact Number",
	phoneName: "Phone Name",
	district: "District",
	chapter: "Chapter",
	inchargeContact: "Incharge Contact",
	inchargeName: "Incharge Name",
	leaderName: "Team Leader",
	supervisorName: "Supervisor",
	leadStatus: 'Lead Status',
	leadResponse: 'Lead Response'
}

export const reportColumnMapping = {
	contactNumber: "Contact Number",
	name: "WORKer",
	allianceName: "Alliance",
	supervisorName: 'Supervisor',
	leaderName: 'Team Leader',
	taskDone: 'Tasks Done',
	taskPending: 'Tasks Pending'
}

export const leadRatingLevels= {
	1: {label: 'Anti Allama', color: red},
	2: {label: 'Time Waste', color: red},
	3: {label: 'Not Interested', color: yellowish},
	4: {label: 'Low Response', color: yellowish},
	5: {label: 'Interested In Knowledge', color: yellowish},
	6: {label: 'Potential WORKer', color: green},
	7: {label: 'VIP Contact', color: green}
  };

export const genderList = [
	{name: 'Male'},
	{name: 'Female'}
]

export const age = [
	{name: 15},
	{name: 16},
	{name: 17},
	{name: 18},
	{name: 19},
	{name: 20},
	{name: 21},
	{name: 22},
	{name: 23},
	{name: 24},
	{name: 25},
	{name: 26},
	{name: 27},
	{name: 28},
	{name: 29},
	{name: 30},
	{name: 31},
	{name: 32},
	{name: 32},
]
