import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Grid2, Paper, Typography } from '@mui/material'
import React from 'react'
import { Donegreen, headingBlue, lightGrey, maroon, mediumGrey, pendingYellow, pureWhite, textGeen, textYellow, yellowish } from '../assets/colors'
import { green, grey, red } from '@mui/material/colors'
import { dailyTasks } from '../data/taskCollections'

const TaskDetailsDialogBox = (props) => {

    var titleStyle={
        backgroundColor: headingBlue,
        color: 'white',
        fontSize:'1rem',
        display: "flex",
        alignItems: 'center'
      }
    
    var gridStyle= {
        textAlign: 'center'
    }

  return (
    <>
        <Dialog maxWidth='sm' fullWidth open={props.detailsDialogStatus} onClose={() => {props.setDetailsDialogStatus(false)}}>
            <DialogTitle sx={{height: "1.8rem", padding: "0.4rem 0rem 0.4rem 1rem"  }}  style={titleStyle}>Task Details</DialogTitle>
            <DialogContent>
                <Box style={{display: "flex", justifyContent: 'center', alignItems: "center", marginTop: '1rem', color: yellowish}}>
                    <Grid container spacing={2} style={{display: "flex", justifyContent: 'center', alignItems: "center"}}>
                        <Grid item xs={6} sm={3} style={gridStyle}>
                            <Typography>{props?.data?.name ? props.data.name : '-'}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} style={gridStyle}>
                            <Typography>{props?.data?.contactNumber ? props.data.contactNumber: '-'}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} style={gridStyle}>
                            <Typography>{props?.data?.leaderName ? props.data.leaderName : '-'}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} style={gridStyle}>
                            <Typography>{props?.data?.supervisorName ? props.data.supervisorName : '-'}</Typography>
                        </Grid>

                    </Grid>
                </Box>
                <Paper style={{padding: "0.2rem 0.5rem 0.3rem 0.5rem", marginTop: "0.7rem"}}>
                <Box style={{display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: "flex-start", color: headingBlue}}>
                    {props?.taskList?.map((task) => 
                        task.dd ?  
                        (
                            (props?.data?.districtsAssigned ===2 && task?.dd === 2) ?
                            (
                                <>
                                    <Box style={{display: 'flex', flexDirection: 'row', width: '100%', borderBottom: `1px solid ${mediumGrey}`}}>
                                <Grid container spacing={1} sx={{padding: "0.2rem"}}>
                                    <Grid item xs={9.5} sm={9.5} style={{display:' flex', flexDirection: 'row'}}>
                                        <Typography style={{fontSize: '0.9rem', marginRight: "0.3rem"}}>{task?.taskTitle2?.split('dd2')?.[0]} <span style={{color: maroon}}>{props?.data?.secondSelectedDistrict}</span> {task?.taskTitle2?.split('dd2')[1]} </Typography>
                                    </Grid>
                                    <Grid item xs={2.5} sm={2.5} style={{display: 'flex', justifyContent: 'flex-end', alignItems: "center"}}>
                                        <Button style={{textTransform: 'capitalize'}} 
                                            sx={{height: "1.25rem", fontSize: '0.8rem', padding: "0rem 0.2rem 0rem 0.2rem", backgroundColor: props?.data?.[task?.taskName] ? Donegreen : pendingYellow, color: props?.data?.[task?.taskName] ? textGeen : textYellow}}>
                                            {props?.data?.[task?.taskName] ? "Done" : "Pending"}
                                        </Button>
                                    </Grid>

                                    </Grid>
                                    </Box>
                                </>
                            ):
                            (task.dd === 1) ? 
                            <>
                                <Box style={{display: 'flex', flexDirection: 'row', width: '100%', borderBottom: `1px solid ${mediumGrey}`}}>
                                <Grid container spacing={1} sx={{padding: "0.2rem"}}>
                                    <Grid item xs={9.5} sm={9.5} style={{display:' flex', flexDirection: 'row'}}>
                                        <Typography style={{fontSize: '0.9rem', marginRight: "0.3rem"}}>{task?.taskTitle2?.split('dd')?.[0]} <span style={{color: maroon}}>{props?.data?.selectedDistrict}</span> {task?.taskTitle2?.split('dd')[1]} </Typography>
                                    </Grid>
                                    <Grid item xs={2.5} sm={2.5} style={{display: 'flex', justifyContent: 'flex-end', alignItems: "center",}}>
                                        <Button style={{textTransform: 'capitalize'}} 
                                            sx={{height: "1.25rem", fontSize: '0.8rem', padding: "0rem 0.2rem 0rem 0.2rem", backgroundColor: props?.data?.[task?.taskName] ? Donegreen : pendingYellow, color: props?.data?.[task?.taskName] ? textGeen : textYellow}}>
                                            {props?.data?.[task?.taskName] ? "Done" : "Pending"}
                                        </Button>
                                    </Grid>

                                    </Grid>
                                    </Box>
                            </> : <></>
                        ):
                         (
                            <Box style={{display: 'flex', flexDirection: 'row', width: '100%', borderBottom: `1px solid ${mediumGrey}`}}>
                                <Grid container spacing={1} sx={{padding: "0.2rem"}}>
                                    <Grid item xs={9.5} sm={9.5}>
                                        <Typography style={{fontSize: '0.9rem'}}>{task?.taskTitle2}</Typography>
                                    </Grid>
                                    <Grid item xs={2.5} sm={2.5} style={{display: 'flex', justifyContent: 'flex-end', alignItems: "center"}}>
                                        <Button style={{textTransform: 'capitalize'}} 
                                            sx={{height: "1.25rem", fontSize: '0.8rem', padding: "0rem 0.2rem 0rem 0.2rem", backgroundColor: props?.data?.[task?.taskName] ? Donegreen : pendingYellow, color: props?.data?.[task?.taskName] ? textGeen : textYellow}}>
                                            {props?.data?.[task?.taskName] ? "Done" : "Pending"}
                                        </Button>
                                    </Grid>

                                </Grid>
                                {/* <Box style={{display: 'flex', alignItems: 'center', width: '90%', padding: "0.1rem"}}>
                                
                                </Box>
                                <Box style={{display: 'flex', width: '10%', padding: "0.2rem", justifyContent: 'flext-end'}}>
                                    
                                </Box> */}
                            </Box>
                        )
                        // task.dd ? 
                        //  props?.data?.gender ==="Female" ?
                        // (
                        //  <>
                        //     <Typography>task?.taskTitle</Typography>
                        //  </>) :
                        //  (<>
                        //     <Typography>vbrjhvbtask?.taskTitle</Typography>
                        //  </>)
                        //  :<>
                        //     <Typography>bchsbvbdsvhb</Typography>
                        //  </>

                    )}
                    {/* <Typography style={{color: props?.data?.shareVideosOfAllama ? `${yellowish}`: `${maroon}`}}>
                        Shared Videos of Allama in {props?.data?.selectedDistrict} FB Groups?
                    </Typography>
                    <Typography style={{color: props?.data?.shareVideosOfAllamaD2 ? `${yellowish}`: `${maroon}`}}>
                        Shared Videos of Allama in {props?.data?.secondSelectedDistrict} FB Groups?
                    </Typography> */}
                    
                </Box>
                </Paper>

            </DialogContent>

        </Dialog>
    </>
  )
}

export default TaskDetailsDialogBox