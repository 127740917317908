import React, { useCallback, useState } from 'react'
import HeaderBar from './HeaderBar'
import ActionBar from './actionBar'
import TopCard from './TopCard'
import { Box, Grid } from '@mui/material'
import { containerStyle } from './ReportDashBoardStyle'
import ReportsTable from './ReportsTable'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchDailyTasks, fetchDailyTimeTasks, fetchMonthlyTasks, fetchOneTimeTasks, fetchWeeklyTasks } from '../backend/backendApis'
import HttpService from '../backend/requestApis'
import { dashboardsList, getCurrentWeek } from '../utils/constants'
import Dashboard from './DashBoard'
import _ from "lodash";
import { getWeekforToday } from '../utils/commonFunctions'
import dayjs from 'dayjs'
import AddDailyTaskDialogBox from './AddDailyTaskDialogBox'
import LeadsDashboard from './leadsDashboard'

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

const ReportDashboard = () => {
    const navigate = useNavigate()
    // const location = useLocation()
	// const dashBoard = location.state || null

    dayjs.extend(utc);
    dayjs.extend(timezone);

    
    const [page, setPage] = React.useState(1)
    const [offset, setOffset] = React.useState(5)
    const [sortBy, setSortBy] = React.useState('WORKer')
    const [sortOrder, setSortOrder] = React.useState(1)
    const [filterOn, setFilterOn] = React.useState('Filter On')
    const [filterBy, setFilterBy] = React.useState('')
    const [selectedDashBoard, setSelectedDashboard] = React.useState()
    const [loadingTableData, setLoadingTableData] = React.useState(false)
    const [feeTableData, setFeeTableData] = React.useState(null)
    const [refreshTableApi, setRefreshTableApis] = React.useState(true)
    const [selectedWeek, setSelectedWeek] = useState(null)
    const [currentWeek, setCurrentWeek] = useState(1)
    const [weekNumber, setWeekNumber] = React.useState(null)
    const [selectedDate, setSelectedDate] = useState(dayjs())
    const [taskDialogStatus, setTaskDialogStatus] = useState(false)


    const updateTaskDialogStatus = () => {
      setTaskDialogStatus(!taskDialogStatus)
    }

    const updateSelectedDashboard = (dashboard) => {
        setPage(1)
        setOffset(10)
        setFilterOn('Filter On')
        setFilterBy('')
        setSelectedDashboard(dashboard)
    }

    const updateSelectedDate = (date) => {
      setSelectedDate(date)
      setRefreshTableApis(!refreshTableApi)
    }

    const updatePage = (page) => {
        setPage(page)
        setRefreshTableApis(!refreshTableApi)
      }
    
      const updateOffset = (offset) => {
        setPage(1)
        setOffset(offset)
        setRefreshTableApis(!refreshTableApi)
      }
    
      const updateSort = (sortBy, sortOrder) => {
        setPage(1)
        setFilterOn('Filter On')
        setFilterBy("")
        setSortBy(sortBy)
        setSortOrder(sortOrder)
        setRefreshTableApis(!refreshTableApi)
      }
    
      const updateFilterOn = (filterOn) => {
        setFilterOn(filterOn)
      }

      const updateFilterBy = (filterByValue) => {
        setFilterBy(filterByValue)
        debouncedApiCall(filterByValue)
    }

      const updateSelectedWeek = (week) => {
        setSelectedWeek(week)
        setWeekNumber(week.split(' ')[1])
        setRefreshTableApis(!refreshTableApi)
      }

    const debouncedApiCall = useCallback(
        _.debounce((filterByValue) => {
          setPage(1)
          setRefreshTableApis((prev) => !prev)
        }, 500),
        []
    );
    
    async function fetchTableData(){
        setLoadingTableData(true)
        let options = {
            url: fetchOneTimeTasks,
            method: 'GET',
            params:{
                sortBy: sortBy,
                sortOrder: sortOrder,
                page: page,
                offset: offset
            },
            headers: {
                Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
            }
        }
        if( filterOn != 'Filter On'){
            let filterOnValue = ''
            switch(filterOn){
              case 'WORKer':
                filterOnValue = 'name'
                break;
              case 'Contact Number':
                filterOnValue = 'contactNumber'
                break;
              case 'Alliance':
                filterOnValue = 'allianceName'
                break;
              case 'Team Leader':
                filterOnValue = 'leaderName'
                break;
              case 'Supervisor':
                filterOnValue = 'supervisorName'
                break;
              default:
                filterOnValue =  null
            }
            options.params.filterOn = filterOnValue
            options.params.filterBy = filterBy
            
          }

        
        HttpService(options).then(data => {
            setFeeTableData(data?.data?.data)
            setLoadingTableData(false)
        }).catch(err => {
            if(err?.status === 403){
                localStorage.clear()
                setLoadingTableData(false)
                navigate('/Login')
              } else {
                setLoadingTableData(false)
              }
        })
    }

    async function fetchDailyTableData(){
        setLoadingTableData(true)
        let options = {
            url: fetchDailyTasks,
            method: 'GET',
            params:{
                reportDate: dayjs(dayjs(dayjs(selectedDate).format()).utc()).tz('Asia/Kolkata').format("YYYY-MM-DD"),
                sortBy: sortBy,
                sortOrder: sortOrder,
                page: page,
                offset: offset
            },
            headers: {
                Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
            }
        }
        if( filterOn != 'Filter On'){
            let filterOnValue = ''
            switch(filterOn){
              case 'WORKer':
                filterOnValue = 'name'
                break;
              case 'Contact Number':
                filterOnValue = 'contactNumber'
                break;
              case 'Alliance':
                filterOnValue = 'allianceName'
                break;
              case 'Team Leader':
                filterOnValue = 'leaderName'
                break;
              case 'Supervisor':
                filterOnValue = 'supervisorName'
                break;
              default:
                filterOnValue =  null
            }
            options.params.filterOn = filterOnValue
            options.params.filterBy = filterBy
            
          }

        
        HttpService(options).then(data => {
            setFeeTableData(data?.data?.data)
            setLoadingTableData(false)
        }).catch(err => {
            if(err?.status === 403){
                localStorage.clear()
                setLoadingTableData(false)
                navigate('/Login')
              } else {
                setLoadingTableData(false)
              }
        })
    }

    async function fetchWeeklyTableData(){
        setLoadingTableData(true)
        let options = {
            url: fetchWeeklyTasks,
            method: 'GET',
            params:{
                weekNumber: weekNumber,
                sortBy: sortBy,
                sortOrder: sortOrder,
                page: page,
                offset: offset
            },
            headers: {
                Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
            }
        }
        if( filterOn != 'Filter On'){
            let filterOnValue = ''
            switch(filterOn){
              case 'WORKer':
                filterOnValue = 'name'
                break;
              case 'Contact Number':
                filterOnValue = 'contactNumber'
                break;
              case 'Alliance':
                filterOnValue = 'allianceName'
                break;
              case 'Team Leader':
                filterOnValue = 'leaderName'
                break;
              case 'Supervisor':
                filterOnValue = 'supervisorName'
                break;
              default:
                filterOnValue =  null
            }
            options.params.filterOn = filterOnValue
            options.params.filterBy = filterBy
            
          }

          console.log("options: ", options)
        
        HttpService(options).then(data => {
            setFeeTableData(data?.data?.data)
            setLoadingTableData(false)
        }).catch(err => {
            if(err?.status === 403){
                localStorage.clear()
                setLoadingTableData(false)
                navigate('/Login')
              } else {
                setLoadingTableData(false)
              }
        })
    }

    async function fetchMonthlyTableData(){
        setLoadingTableData(true)
        let options = {
            url: fetchMonthlyTasks,
            method: 'GET',
            params:{
                monthNumber: 1,
                sortBy: sortBy,
                sortOrder: sortOrder,
                page: page,
                offset: offset
            },
            headers: {
                Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
            }
        }
        if( filterOn != 'Filter On'){
            let filterOnValue = ''
            switch(filterOn){
              case 'WORKer':
                filterOnValue = 'name'
                break;
              case 'Contact Number':
                filterOnValue = 'contactNumber'
                break;
              case 'Alliance':
                filterOnValue = 'allianceName'
                break;
              case 'Incharge':
                filterOnValue = 'leaderName'
                break;
              case 'Supervisor':
                filterOnValue = 'supervisorName'
                break;
              default:
                filterOnValue =  null
            }
            options.params.filterOn = filterOnValue
            options.params.filterBy = filterBy
            
          }

        
        HttpService(options).then(data => {
            setFeeTableData(data?.data?.data)
            setLoadingTableData(false)
        }).catch(err => {
            if(err?.status === 403){
                localStorage.clear()
                setLoadingTableData(false)
                navigate('/Login')
              } else {
                setLoadingTableData(false)
              }
        })
    }

    React.useEffect(() => {
    try{
        if( selectedDashBoard === 'One Time Task'){
            fetchTableData()
        } else if ( selectedDashBoard === 'Daily Task'){
            fetchDailyTableData()
        } else if ( selectedDashBoard === 'Weekly Task'){
            if(! selectedWeek ){
              let currentWeek = getCurrentWeek()
              console.log("current week: ", currentWeek)
              setCurrentWeek(currentWeek)
              setSelectedWeek(currentWeek['name'])
              setWeekNumber(currentWeek['weekNumber'])
            }
            fetchWeeklyTableData()
        } else if ( selectedDashBoard === 'Monthly Task'){
            fetchMonthlyTableData()
        }

    } catch(err){
        console.log("Error in fetching table data: ", err)
    }
    
},[selectedDashBoard, refreshTableApi])

  return (
    <>
        <HeaderBar setFunc={updateSelectedDashboard}></HeaderBar>
        {selectedDashBoard === 'Members' ? 
            <Dashboard></Dashboard> : 
            selectedDashBoard === 'Leads' ? 
            <LeadsDashboard></LeadsDashboard> :
        <>
        <ActionBar dashBoard= {selectedDashBoard} selectedWeek={selectedWeek} currentWeek={currentWeek} updateSelectedWeek={updateSelectedWeek} selectedDate={selectedDate} setSelectedDate={updateSelectedDate} updateTaskDialogStatus={updateTaskDialogStatus}></ActionBar>
        <Box style={containerStyle}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                    <TopCard heading='Total Tasks'></TopCard>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TopCard heading='Tasks Done'></TopCard>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TopCard heading='Tasks Pending'></TopCard>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TopCard heading='Most Missed Task'></TopCard>
                </Grid>

            </Grid>
            <ReportsTable dashBoard={selectedDashBoard} pageValue={page} updatePage={updatePage} offsetValue={offset} updateOffset={updateOffset} updateSort= {updateSort} data={feeTableData} loading={loadingTableData} filterOnValue={filterOn} updateFilterOn={updateFilterOn} filterByValue={filterBy} updateFilterBy={updateFilterBy} selectedDate={selectedDate} ></ReportsTable>
            <AddDailyTaskDialogBox taskDialogStatus={taskDialogStatus} updateTaskDialogStatus={updateTaskDialogStatus}></AddDailyTaskDialogBox>
        </Box>
        </>
        }
    </>
  )
}

export default ReportDashboard