import React, { useCallback } from 'react'
import HeaderBar from './HeaderBar'
import ActionBar from './actionBar'
import { Box, Grid } from '@mui/material'
import TopCard from './TopCard'
import { containerStyle } from './ReportDashBoardStyle'
import LeadsTable from './LeadsTable'
import { fetchLeadsTableData, getLeadsTiles } from '../backend/backendApis'
import HttpService from '../backend/requestApis'
import { useNavigate } from 'react-router-dom'
import _ from "lodash";
import { bgRed, green, hMapBlue, maroon, orange, red, yellowish } from '../assets/colors'

const LeadsDashboard = () => {

    const navigate = useNavigate()

    const [page, setPage] = React.useState(1)
    const [offset, setOffset] = React.useState(5)
    const [sortBy, setSortBy] = React.useState('WORKer')
    const [sortOrder, setSortOrder] = React.useState(1)
    const [filterOn, setFilterOn] = React.useState('Filter On')
    const [filterBy, setFilterBy] = React.useState('')
    const [selectedDashBoard, setSelectedDashboard] = React.useState()
    const [refreshTableApi, setRefreshTableApis] = React.useState(true)
    const [tableData, setTableData] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(false)
    const [isTopCardLoading, setIsTopCardLoading]= React.useState(false)
    const [tilesData, setTilesData] = React.useState([])
    const [selectedTile, setSelectedTile] = React.useState('followUp')
    const updateSelectedDashboard = (dashboard) => {
        setPage(1)
        setOffset(10)
        setFilterOn('Filter On')
        setFilterBy('')
        setSelectedDashboard(dashboard)
    }

    const updatePage = (page) => {
        setPage(page)
        setRefreshTableApis(!refreshTableApi)
      }
    
      const updateOffset = (offset) => {
        setPage(1)
        setOffset(offset)
        setRefreshTableApis(!refreshTableApi)
      }
    
      const updateSort = (sortBy, sortOrder) => {
        setPage(1)
        setFilterOn('Filter On')
        setFilterBy("")
        setSortBy(sortBy)
        setSortOrder(sortOrder)
        setRefreshTableApis(!refreshTableApi)
      }
    
      const updateFilterOn = (filterOn) => {
        setFilterOn(filterOn)
      }

      const handleSelectedTile = (tileValue) => {
        setPage(1)
        setSelectedTile(() => tileValue)
      }

      const updateFilterBy = (filterByValue) => {
        setFilterBy(filterByValue)
        debouncedApiCall(filterByValue)
    }

    const debouncedApiCall = useCallback(
        _.debounce((filterByValue) => {
          setPage(1)
          setRefreshTableApis((prev) => !prev)
        }, 500),
        []
    );

    const handleDownloadAllRecords = () => {
        return new Promise((resolve, reject) => {
            let options = {
                url: fetchLeadsTableData,
                method: 'GET',
                params:{
                    leadStatusFilter: {"$in": [selectedTile]}
                },
                headers: {
                    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
                }
            }

            if(selectedTile === 'notInitiated'){
                options['params']['leadStatusFilter'] = {"$nin": ['followUp', 'notReachable', 'closed', 'wrongNumber']}
            }

            if(selectedTile === 'all'){
                options['params']['leadStatusFilter'] = "all"

            }

            HttpService(options).then(data => {
                resolve(data?.data?.data?.data)
            }).catch(err => {
                if(err?.status === 403){
                    localStorage.clear()
                    reject()
                    // setLoadingTableData(false)
                    navigate('/Login')
                  } else {
                    // setLoadingTableData(false)
                    reject()
                  }
            })
        })
    }

    React.useEffect(() => {
        try{
            setIsLoading(true)
            let options = {
                url: fetchLeadsTableData,
                method: 'GET',
                params:{
                    sortBy: sortBy,
                    sortOrder: sortOrder,
                    page: page,
                    offset: offset,
                    leadStatusFilter: {"$in": [selectedTile]}
                },
                headers: {
                    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
                }
            }

            if(selectedTile === 'notInitiated'){
                options['params']['leadStatusFilter'] = {"$nin": ['followUp', 'notReachable', 'closed', 'wrongNumber']}
            }

            if(selectedTile === 'all'){
                options['params']['leadStatusFilter'] = "all"

            }

            if( filterOn != 'Filter On'){
                let filterOnValue = ''
                switch(filterOn){
                    case 'Lead Name':
                        filterOnValue = 'name'
                        break;
                    case 'Lead Contact':
                        filterOnValue = 'contactNumber'
                        break;
                    case 'Phone Name':
                        filterOnValue = 'phoneName'
                        break;
                    case 'District':
                        filterOnValue = 'district'
                        break;
                    case 'Chapter':
                        filterOnValue = 'chapter'
                        break;
                    default:
                        filterOnValue = null
                }
                options.params.filterOn = filterOnValue
                options.params.filterBy = filterBy   
            }
            HttpService(options).then(data => {
                setTableData(data?.data?.data)
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
                if(err?.status === 403){
                    localStorage.clear()
                    // setLoadingTableData(false)
                    navigate('/Login')
                  } else {
                    // setLoadingTableData(false)
                  }
            })
        } catch(err){
            setIsLoading(false)
            console.log("Error in fetching Table Data: ", err)
        }
    }, [selectedDashBoard, refreshTableApi, selectedTile])

    React.useEffect(() => {
        try{
            setIsTopCardLoading(true)
            let options = {
                url: getLeadsTiles,
                method: 'GET',
                headers: {
                    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null 
                }
            }
            HttpService(options).then(data => {
                setTilesData(data?.data?.data)
                setIsTopCardLoading(false)
            }).catch(err => {
                setIsTopCardLoading(false)
                if(err?.status === 403){
                    localStorage.clear()
                    // setLoadingTableData(false)
                    navigate('/Login')
                  } else {
                    // setLoadingTableData(false)
                  }
            })

        } catch(err){
            setIsTopCardLoading(false)
            console.log("Error in fetching leads Tiles Data: ", err)
        }
    }, [selectedDashBoard])

    return (
        <>
            <ActionBar dashBoard = 'leads'></ActionBar>
            <Box style={containerStyle}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={2}
                        onClick={() => handleSelectedTile('all')}
                    >
                        <TopCard heading='All Leads'
                            data={(
                                (tilesData?.notInitiated ? tilesData?.notInitiated : 0)
                                + (tilesData?.followUp ? tilesData?.followUp : 0)
                                + (tilesData?.notReachable ? tilesData?.notReachable : 0 )
                                + (tilesData?.wrongNumber ? tilesData?.wrongNumber : 0 )
                                + (tilesData?.closed ? tilesData?.closed : 0)
                            )}
                            color={hMapBlue}
                            isSelected={selectedTile === 'all'}
                            
                        ></TopCard>
                    </Grid>
                    <Grid item xs={6} sm={2}
                        onClick={() => handleSelectedTile('notInitiated')}
                    >
                        <TopCard heading='Not Initiated'
                            data={tilesData?.notInitiated ? tilesData?.notInitiated : 0}
                            color={hMapBlue}
                            isSelected={selectedTile === 'notInitiated'}
                        ></TopCard>
                    </Grid>
                    <Grid item xs={6} sm={2}
                        onClick={() => handleSelectedTile('followUp')}
                    >
                        <TopCard heading='Follow Up'
                            data={tilesData?.followUp ? tilesData?.followUp : 0}
                            color={yellowish}
                            isSelected={selectedTile === 'followUp'}
                        ></TopCard>
                    </Grid>
                    <Grid item xs={6} sm={2}
                        onClick={() => handleSelectedTile('notReachable')}
                    >
                        <TopCard heading='Not Reachable'
                            data={tilesData?.notReachable ? tilesData?.notReachable : 0}
                            color={orange}
                            isSelected={selectedTile === 'notReachable'}
                        ></TopCard>
                    </Grid>
                    <Grid item xs={6} sm={2}
                        onClick={() => handleSelectedTile('wrongNumber')}
                    >
                        <TopCard heading='Wrong Number'
                            data={tilesData?.wrongNumber ? tilesData?.wrongNumber : 0}
                            color={maroon}
                            isSelected={selectedTile === 'wrongNumber'}
                        ></TopCard>
                    </Grid>
                    <Grid item xs={6} sm={2}
                        onClick={() => handleSelectedTile('closed')}
                    >
                        <TopCard heading='Closed'
                            data={tilesData?.closed ? tilesData?.closed : 0}
                            color= {green}
                            isSelected={selectedTile === 'closed'}
                        ></TopCard>
                    </Grid>

                </Grid>
                <LeadsTable dashBoard='leads' pageValue={page} 
                updatePage={updatePage} 
                offsetValue={offset} 
                updateOffset={updateOffset} 
                updateSort= {updateSort} 
                data={tableData} 
                // loading={loadingTableData} 
                filterOnValue={filterOn} 
                updateFilterOn={updateFilterOn} 
                filterByValue={filterBy} 
                updateFilterBy={updateFilterBy}
                isLoading={isLoading}
                handleDownloadAllRecords={handleDownloadAllRecords}
                ></LeadsTable>

            </Box>
        </>
    )
}

export default LeadsDashboard