import { Box, Rating, Typography } from '@mui/material'
import React from 'react'
import { leadRatingLevels } from '../utils/constants'

const CustomLeadsRating = (props) => {
    // const [value, setValue] = React.useState();
    const [hover, setHover] = React.useState(-1);
  return (
    <Box sx={{ width: 200, display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}>
        <Rating
            max={7}
            value={props.value}
            sx={{
                '& .MuiRating-iconFilled':{
                color: `${leadRatingLevels[hover !== -1 ? hover : props.value]?.['color']}`
                }
            }}
            onChange={(event, newValue) => {
                props.valueFunc(newValue)
              }}
            onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}

        >

        </Rating>
        <Box style={{width: "100%", color: `${leadRatingLevels[hover !== -1 ? hover : props.value]?.['color']}`, fontSize: '1.rem'}}>
            <Typography>
                {leadRatingLevels[hover !== -1 ? hover : props.value]?.['label'] ? leadRatingLevels[hover !== -1 ? hover : props.value]?.['label'] : "   "}
            </Typography>
        </Box>

    </Box>
  )
}

export default CustomLeadsRating