import React, { useCallback } from "react";
// import { Box, Paper, Button, Typography, ButtonGroupContext, Grid, Container } from '@mui/material';
// import HeaderBar from './header';
// import TopCard from './topCard';
// import ActionBar from './actionBar';
// import FeeTable from './feeTable';
// import { feeCardDetails, feeTableData, getSessionList, getTotalAmountCardData, health, totalAmountCardData } from '../backendIntegration/apis';
// import CustomDialogBox from './customDialogBox';
import _ from "lodash";
// import AddSessionDialogBox from './addSessionDialogBox';
import { useNavigate } from "react-router-dom";
// import BulkUploadDialog from './bulkUploadDialog';
// import CustomSnackbar from './customSnackbar';
import Header from "./Headers";
import HttpService from "../backend/requestApis";
import Loader from "./Loader";
import ActionBar from "./actionBar";
import HeaderBar from "./HeaderBar";
import TopCard from "./TopCard";
import { Box, Grid } from "@mui/material";
import WORKersTable from "./WORKersTable";
import { districtsPickedCount, fetchAssignmentTableData } from "../backend/backendApis";
import AddLeaderDialogBox from "./AddLeaderDialogBox";
import CreateAllianceDashboard from "./CreateAllianceDashboard";
import EditAllianceDashboard from "./EditAllianceDashboard";
import CreateLeaderAccount from "./CreateLeaderAccount";

function Dashboard() {
	const [districtsCardData, setDistrictsCardData] = React.useState([]);
	const [totalAmountCardData, setTotalAmountCardData] = React.useState(0);
	const [feetableData, setFeeTableData] = React.useState([]);
	const [page, setPage] = React.useState(1);
	const [offset, setOffset] = React.useState(5);
	const [sortBy, setSortBy] = React.useState("name");
	const [sortOrder, setSortOrder] = React.useState(1);
	const [filterOn, setFilterOn] = React.useState("Filter On");
	const [filterBy, setFilterBy] = React.useState("");
	const [
		addLeaderSupervisorDialogBoxStatus,
		setAddLeaderSupervisorDialogBoxStatus,
	] = React.useState(false);
	const [createAllianceDialogBoxStatus, setCreateAllianceDialogBoxStatus] =
		React.useState(false);
	const [editAllianceDialogStatus, setEditAllianceDialogStatus] =
		React.useState(false);
	const [feeDialogStatus, setFeeDialogStatus] = React.useState(false);
	// const [addSessionDialogStatus, setAddSessionDialogStatus] = React.useState(false)
	const [bulkUploadDialogStatus, setBulkUploadDialogStatus] =
		React.useState(false);
	const [isRefresh, setIsRefresh] = React.useState(false);
	const [loadingSessionData, setLoadingSessionData] = React.useState(false);
	const [loadingCardData, setLoadingCardData] = React.useState(false);
	const [loadingAmountCardData, setLoadingAmountCardData] =
		React.useState(false);
	const [loadingTableData, setLoadingTableData] = React.useState(true);
	const [refreshTopApis, setRefreshTopApis] = React.useState(true);
	const [refreshTableApi, setRefreshTableApis] = React.useState(true);
	const [filterByUpdated, setFilterByUpdated] = React.useState(false);
	const [designation, setDesignation] = React.useState(null);
	const [createLeaderAccDialogStatus, setCreateLeaderAccDialogStatus] = React.useState(false)

	const navigate = useNavigate();

	const updateRefreshTableApi = () => {
		setRefreshTableApis(!refreshTableApi);
	};

	const updateCreateLeaderAccDialogStatus = () => {
		setCreateLeaderAccDialogStatus(!createLeaderAccDialogStatus)
	}

	const updateAddLeaderSupervisorDialogStatus = (refresh = false) => {
		setAddLeaderSupervisorDialogBoxStatus(!addLeaderSupervisorDialogBoxStatus);
		if (refresh) {
			setRefreshTopApis(!refreshTopApis);
		}
	};

	const updateCreateAllianceDialogStatus = (refresh = false) => {
		setCreateAllianceDialogBoxStatus(!createAllianceDialogBoxStatus);
		if (refresh) {
			setRefreshTableApis(!refreshTableApi);
		}
	};

	const updateEditAllianceDialogStatus = (refresh = false) => {
		setEditAllianceDialogStatus(!editAllianceDialogStatus);
		if (refresh) {
			setRefreshTableApis(!refreshTableApi);
		}
	};

	const updatePage = (page) => {
		setPage(page);
		setRefreshTableApis(!refreshTableApi);
	};

	const updateOffset = (offset) => {
		setPage(1);
		setOffset(offset);
		setRefreshTableApis(!refreshTableApi);
	};

	const updateSort = (sortBy, sortOrder) => {
		setPage(1);
		setFilterOn("Filter On");
		setFilterBy("");
		setSortBy(sortBy);
		setSortOrder(sortOrder);
		setRefreshTableApis(!refreshTableApi);
	};

	const updateFilterOn = (filterOn) => {
		setFilterOn(filterOn);
	};

	const updateFilterBy = (filterByValue) => {
		setFilterBy(filterByValue);
		debouncedApiCall(filterByValue);
	};

	const debouncedApiCall = useCallback(
		_.debounce((filterByValue) => {
			setPage(1);
			setRefreshTableApis(true);
		}, 500),
		[]
	);

	async function fetchDistrictsPickedCount () {
		setLoadingCardData(true)
		let options = {
			url: districtsPickedCount,
			method: 'GET',
			headers: {
				Authorization: localStorage.getItem("token")
					? `Bearer ${localStorage.getItem("token")}`
					: null,
			},
		}
		HttpService(options).then((data) => {
			setDistrictsCardData(data.data.data[0])
			setLoadingCardData(false)
		}).catch((err) => {
			if (err?.status === 403) {
				localStorage.clear();
				setLoadingTableData(false);
				navigate("/");
			} else {
				setLoadingCardData(false)
			}
		})
	}

	async function fetchTableData() {
		setLoadingTableData(true);
		let options = {
			url: fetchAssignmentTableData,
			method: "GET",
			params: {
				sortBy: sortBy,
				sortOrder: sortOrder,
				page: page,
				offset: offset,
			},
			headers: {
				Authorization: localStorage.getItem("token")
					? `Bearer ${localStorage.getItem("token")}`
					: null,
			},
		};
		if (filterOn != "Filter On") {
			let filterOnValue = "";
			switch (filterOn) {
				case "WORKer":
					filterOnValue = "name";
					break;
				case "Gender":
					filterOnValue = "gender";
					break;
				case "Contact Number":
					filterOnValue = "contactNumber";
					break;
				case "District":
					filterOnValue = "district";
					break;
				case "Chapter":
					filterOnValue = "chapter";
					break;
				case "Selected District 1":
					filterOnValue = "selectedDistrict";
					break;
				case "Selected Chapter 1":
					filterOnValue = "selectedChapter";
					break;
				case "Selected District 2":
					filterOnValue = "secondSelectedDistrict";
					break;
				case "Selected Chapter 2":
					filterOnValue = "secondSelectedChapter";
					break;
				case "Alliance":
					filterOnValue = "allianceName";
					break;
				case "Team Leader":
					filterOnValue = "leaderName";
					break;
				case "Supervisor":
					filterOnValue = "supervisorName";
					break;
				default:
					filterOnValue = null;
			}
			options.params.filterOn = filterOnValue;
			options.params.filterBy = filterBy;
		}
		HttpService(options)
			.then((data) => {
				setFeeTableData(data.data.data);
				setLoadingTableData(false);
			})
			.catch((err) => {
				if (err?.status === 403) {
					localStorage.clear();
					setLoadingTableData(false);
					navigate("/");
				} else {
					setLoadingTableData(false);
				}
			});
	}

	React.useEffect(() => {
		try {
			fetchTableData();
		} catch (err) {
			console.log("Error in fetching table data: ", err);
		}
	}, [refreshTableApi, filterBy]);

	React.useEffect(() => {
		async function getDesignation() {
			let designation = await localStorage.getItem("designation");
			if (designation) {
				setDesignation(designation);
			}
		}
		getDesignation();
		fetchDistrictsPickedCount()
	}, []);

	var containerStyle = {
		padding: "0.7rem 1.5rem 1.5rem 1.5rem",
	};
	return (
		<>
			<ActionBar
				updateAddLeaderSupervisorDialogStatus={
					updateAddLeaderSupervisorDialogStatus
				}
				updateCreateAllianceDialogStatus={updateCreateAllianceDialogStatus}
				updateEditAllianceDialogStatus={updateEditAllianceDialogStatus}
				updateCreateLeaderAccDialogStatus={updateCreateLeaderAccDialogStatus}
				isAdmin={true}
			></ActionBar>
			<Box style={containerStyle}>
				<Grid container spacing={2}>
					<Grid item xs={6} sm={3}>
						<TopCard
							heading="Districts Picked (Male)"
							data={districtsCardData.districtPickedMale}
						></TopCard>
					</Grid>
					<Grid item xs={6} sm={3}>
						<TopCard
							heading="Districts Picked (Female)"
							data={districtsCardData.districtPickedFemale}
						></TopCard>
					</Grid>
					<Grid item xs={6} sm={3}>
						<TopCard
							heading="Best Alliance (Male)"
							// data={feeCardData.studentsFeeNotPaid}
						></TopCard>
					</Grid>
					<Grid item xs={6} sm={3}>
						<TopCard
							heading="Best Alliance (Female)"
							data={totalAmountCardData}
						></TopCard>
					</Grid>
				</Grid>
				<WORKersTable
					dashBoard='members'
					pageValue={page}
					updatePage={updatePage}
					offsetValue={offset}
					updateOffset={updateOffset}
					data={feetableData}
					updateSort={updateSort}
					filterOnValue={filterOn}
					updateFilterOn={updateFilterOn}
					filterByValue={filterBy}
					updateFilterBy={updateFilterBy}
					refreshTableApi={updateRefreshTableApi}
					designation={designation}
				></WORKersTable>
			</Box>

			<AddLeaderDialogBox
				addLeaderSupervisorDialogBoxStatus={addLeaderSupervisorDialogBoxStatus}
				updateAddLeaderSupervisorDialogStatus={
					updateAddLeaderSupervisorDialogStatus
				}
			></AddLeaderDialogBox>
			<CreateAllianceDashboard
				createAllianceDialogBoxStatus={createAllianceDialogBoxStatus}
				updateCreateAllianceDialogStatus={updateCreateAllianceDialogStatus}
			></CreateAllianceDashboard>

			<EditAllianceDashboard
				editAllianceDialogStatus={editAllianceDialogStatus}
				updateEditAllianceDialogStatus={updateEditAllianceDialogStatus}
			/>

			<CreateLeaderAccount
				createLeaderAccDialogStatus={createLeaderAccDialogStatus}
				updateCreateLeaderAccDialogStatus={updateCreateLeaderAccDialogStatus}
			>

			</CreateLeaderAccount>

			{loadingSessionData ||
			loadingCardData ||
			loadingAmountCardData ||
			loadingTableData ? (
				<Loader></Loader>
			) : (
				<></>
			)}
		</>
	);
}

export default Dashboard;
