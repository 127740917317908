
// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// const BackArrow = ({ fallbackPath = "/", style = {}, label = "Back" }) => {
//   const navigate = useNavigate();
//   const [isHovered, setIsHovered] = useState(false);

//   const handleBack = () => {
//     console.log("Navigating back");
//     navigate(-1, { replace: true });
//   };

//   const defaultStyles = {
//     border: "none",
//     background: "transparent",
//     cursor: "pointer",
//     fontSize: "22px",
//     display: "flex",
//     alignItems: "center",
//     gap: "8px",
//     padding: "8px 16px",
//     color: "#374151",
//     transition: "all 0.2s ease",
//     outline: "none",
//   };

//   const iconStyles = {
//     transform: isHovered ? 'translateX(-4px)' : 'translateX(0)',
//     transition: 'transform 0.2s ease',
//   };
  
//   return (
//     <button
//       onClick={handleBack}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//       style={{
//         ...defaultStyles,
//         ...style, // for custom styles
//       }}
//     >
//       <ArrowBackIcon style={iconStyles}/> {label}
//     </button>
//   );
// };

// export default BackArrow;


import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BackArrow = ({ path = "/", style = {}, label = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  const handleBack = () => {
    // Use the provided path if the navigation history is empty
    if (window.history.state && window.history.state.idx > 0) {
      console.log("Navigating back");
      navigate(-1, { replace: true });
    } else {
      console.log(`Fallback to path: ${path}`);
      navigate(path, { replace: true });
    }
  };

  const defaultStyles = {
    border: "none",
    background: "transparent",
    cursor: "pointer",
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
    // gap: "8px",
    padding: "0.1rem 0.1rem 0rem 0rem",
    color: "#374151",
    transition: "all 0.2s ease",
    outline: "none",
  };

  const iconStyles = {
    transform: isHovered ? "translateX(-4px)" : "translateX(0)",
    transition: "transform 0.2s ease",
  };

  return (
    <button
      onClick={handleBack}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        ...defaultStyles,
        ...style, // for custom styles
      }}
    >
      <ArrowBackIcon style={iconStyles} /> {label}
    </button>
  );
};

export default BackArrow;
