import React, { useEffect, useState } from "react";
import { buttonStyle, containerStyle, paperStyle } from "../utils/commonCSS";
import { imgContainer, imgStyle } from "./formStyle";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import CustomTextField from "./CustomTextField";
import CustomDropDown from "./CustomDropDown";
import { genderList } from "../utils/constants";
import HttpService from "../backend/requestApis";
import {
	addNewLead,
	getDistrictsList,
	getStatesList,
} from "../backend/backendApis";
import {
	validateAge,
	validateContact,
	validateDistrict,
	validateGender,
	validateName,
	validateRefreeContact,
	validateRefreeName,
	validateState,
} from "../utils/validations";
import Loader from "./Loader";
import ReportFormSubmitMessage from "./ReportFormSubmitMessage";
import { maroon, red } from "../assets/colors";

function ShareReferralForm() {
	const [states, setStates] = useState([]);
	const [districts, setDistricts] = useState([]);
	const [selectedState, setSelectedState] = useState("Select State");
	const [selectedDistrict, setSelectedDistrict] = useState("Select District");
	const [selectedGender, setSelectedGender] = useState("Select Gender");
	const [leadName, setLeadName] = useState("");
	const [leadContact, setLeadContact] = useState("");
	const [leadAge, setLeadAge] = useState(0);
	const [refreeName, setRefreeName] = useState("");
	const [refreeContact, setRefreeContact] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [apiError, setApiError] = useState("");
	const [phoneName, setPhoneName] = useState("");
	const [contactError, setContactError] = useState("");
	const [leadNameError, setLeadNameError] = useState("");
	const [genderError, setGenderError] = useState("");
	const [ageError, setAgeError] = useState("");
	const [districtError, setDistrictError] = useState("");
	const [stateError, setStateError] = useState("");
	const [refreeContactError, setRefreeContactError] = useState("");
	const [refreeNameError, setRefreeNameError] = useState("");
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	useEffect(() => {
		try {
			setIsLoading(true);
			let options = {
				url: getStatesList,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("token")
						? `Bearer ${localStorage.getItem("token")}`
						: null,
				},
			};

			HttpService(options)
				.then((response) => {
					if (response.data.status === 1) {
						setStates(response.data.data);
						// console.log(response.data.data);
						setIsLoading(false);
					} else {
						setApiError(
							"Error in fetching states list. Please Contact Support"
						);
						setIsLoading(false);
					}
				})
				.catch((err) => {
					console.log("Error in fetching states list.", err);
					if (err.status === 409) {
						setApiError(err?.response?.data?.description);
					} else {
						setApiError(
							"Error in fetching states list. Please Contact Support"
						);
					}
					setIsLoading(false);
				});
		} catch (err) {
			console.log("Error in fetching states list form.", err);
			setApiError("Error in fetching states list. Please Contact Support");
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		if (selectedState === "Select State") return;
		try {
			setIsLoading(true);
			let options = {
				url: getDistrictsList,
				method: "GET",
				params: {
					state: selectedState,
				},
				headers: {
					Authorization: localStorage.getItem("token")
						? `Bearer ${localStorage.getItem("token")}`
						: null,
				},
			};

			HttpService(options)
				.then((response) => {
					if (response.data.status === 1) {
						setDistricts(response.data.data);
						// console.log(response.data.data);
						setSelectedDistrict("Select District");
						setIsLoading(false);
					} else {
						setApiError(
							"Error in fetching districts list. Please Contact Support"
						);
						setIsLoading(false);
					}
				})
				.catch((err) => {
					console.log("Error in fetching districts list.", err);
					if (err.status === 409) {
						setApiError(err?.response?.data?.description);
					} else {
						setApiError(
							"Error in fetching districts list. Please Contact Support"
						);
					}
					setIsLoading(false);
				});
		} catch (err) {
			console.log("Error in fetching districts list.", err);
			setApiError("Error in fetching districts list. Please Contact Support");
			setIsLoading(false);
		}
	}, [selectedState]);

	async function handleAddLead() {
		if (!validateForm()) {
			console.log("Validation failed. API call not made.");
			return;
		}

		try {
			setIsLoading(true);
			// let token = await localStorage.getItem("token");

			let options = {
				url: addNewLead,
				method: "POST",
				payload: {
					gender: selectedGender,
					contactNumber: leadContact,
					name: leadName,
					age: leadAge,
					district: selectedDistrict,
					chapter: selectedState,
					...(phoneName && { phoneName }),
					...(refreeName && { refreeName }),
					...(refreeContact && { refreeContact }),
				},
				headers: {
					// Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			};

			HttpService(options)
				.then((response) => {
					if (response.data.status === 1) {
						console.log(response);
						resetForm(
							"",
							"Select Gender",
							"",
							"",
							0,
							"Select State",
							"Select District",
							"",
							""
						);
						setIsLoading(false);
						setIsFormSubmitted(true);
					} else {
						setApiError("Error in submitting. Please Contact Support");
						setIsLoading(false);
					}
				})
				.catch((err) => {
					console.log("Error in submiting form.", err);
					if (err.status === 409) {
						setApiError(err?.response?.data?.description);
					} else {
						setApiError("Error in submitting. Please Contact Support");
					}
					setIsLoading(false);
				});
		} catch (err) {
			console.log("Error in submitting form.", err);
			setApiError("Error in submitting. Please Contact Support");
			setIsLoading(false);
		}
	}

	function resetForm(
		name,
		gender,
		phoneName,
		contactNumber,
		age,
		selectedState,
		selectedDistrict,
		referBy,
		refreeContact
	) {
		setLeadName(name);
		setSelectedGender(gender);
		setPhoneName(phoneName);
		setLeadContact(contactNumber);
		setLeadAge(age);
		setSelectedState(selectedState);
		setSelectedDistrict(selectedDistrict);
		setRefreeName(referBy);
		setRefreeContact(refreeContact);
	}
	const validateForm = () => {
		let isValid = true;
		const leadNameError = validateName(leadName, "Name");
		const contactError = validateContact(leadContact);
		if (leadAge) {
			const ageError = validateAge(leadAge);
		}
		const genderError = validateGender(selectedGender);
		const districtError = validateDistrict(selectedDistrict);
		const stateError = validateState(selectedState);
		const refreeNameError = validateRefreeName(refreeName);
		const refreeContactError = validateRefreeContact(refreeContact);

		setLeadNameError(leadNameError);
		setContactError(contactError);
		setAgeError(ageError);
		setGenderError(genderError);
		setDistrictError(districtError);
		setStateError(stateError);
		setRefreeNameError(refreeNameError);
		setRefreeContactError(refreeContactError);

		if (
			leadNameError ||
			contactError ||
			ageError ||
			refreeContactError ||
			genderError ||
			districtError ||
			stateError ||
			refreeNameError
		) {
			isValid = false;
		}

		return isValid;
	};

	function handleSelectedState(state) {
		console.log("Selected State:", state);
		setSelectedState(state);
		setStateError("");
	}
	function handleSelectedDistrict(district) {
		setSelectedDistrict(district);
		setDistrictError("");
	}

	function handleSelectedGender(gender) {
		setSelectedGender(gender);
		setGenderError("");
	}

	function handleadName(leadName) {
		setLeadName(leadName);
		setLeadNameError("");
	}

	function handleContact(contact) {
		setLeadContact(contact);
		setContactError("");
	}

	function handleRefreeContact(contact) {
		setRefreeContact(contact);
		setRefreeContactError("");
	}

	function handleRefreeName(rname) {
		setRefreeName(rname);
		setRefreeNameError("");
	}

	function handleAge(age) {
		setLeadAge(Number(age));
		setAgeError("");
	}

	if (isLoading) return <Loader />;

	return (
		<>
			<div style={{ ...containerStyle, marginTop: "1.2rem" }}>
				<div style={imgContainer}>
					<img
						src={require("../assets/images/WORK_logo.png")}
						style={imgStyle}
					></img>
					<Paper
						style={{
							paperStyle,
							padding: "1rem 0.5rem 1rem 0.5rem",
							marginTop: "1rem",
						}}
					>
						{isFormSubmitted ? (
							<ReportFormSubmitMessage
								message="Thanks for the submission!"
								isLeadSubmission="true"
								setIsFormSubmitted={setIsFormSubmitted}
								setApiError={setApiError}
							/>
						) : (
							<Box>
								<Grid container spacing={1}>
									<Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
										<Typography
											style={{
												fontSize: "0.9rem",
												color: maroon,
												fontWeight: 600,
											}}
										>
											Lead Details
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12}>
										<CustomTextField
											placeHolder="Name"
											value={leadName}
											regular="true"
											setFunc={handleadName}
										></CustomTextField>
										{leadNameError && (
											<Typography
												color={red}
												variant="body2"
												display="block"
												style={{
													fontSize: "0.7rem",
													width: "100%",
													textAlign: "left",
													padding: "0.2rem 0.8rem 0rem 0.8rem",
												}}
											>
												{leadNameError}
											</Typography>
										)}
									</Grid>

									<Grid item xs={6} sm={6}>
										<CustomDropDown
											list={genderList}
											value={selectedGender}
											setFunc={handleSelectedGender}
											placeHolder="Select Gender"
										></CustomDropDown>
										{genderError && (
											<Typography
												color={red}
												variant="body2"
												display="block"
												style={{
													fontSize: "0.7rem",
													width: "100%",
													textAlign: "left",
													padding: "0.2rem 0.8rem 0rem 0.8rem",
												}}
											>
												{genderError}
											</Typography>
										)}
									</Grid>
									<Grid item xs={6} sm={6}>
										<CustomTextField
											placeHolder="phoneName (optional)"
											value={phoneName}
											setFunc={(pname) => setPhoneName(pname)}
										></CustomTextField>
									</Grid>
									<Grid item xs={7} sm={7}>
										<CustomTextField
											placeHolder="Contact Number"
											value={leadContact}
											setFunc={handleContact}
											regular="true"
										></CustomTextField>
										{contactError && (
											<Typography
												color={red}
												variant="body2"
												display="block"
												style={{
													fontSize: "0.7rem",
													width: "100%",
													textAlign: "left",
													padding: "0.2rem 0.8rem 0rem 0.8rem",
												}}
											>
												{contactError}
											</Typography>
										)}
									</Grid>

									<Grid item xs={5} sm={5}>
										<CustomTextField
											placeHolder="Age (optional)"
											value={leadAge}
											setFunc={handleAge}
										></CustomTextField>
										{ageError && (
											<Typography
												color={red}
												variant="body2"
												display="block"
												style={{
													fontSize: "0.7rem",
													width: "100%",
													textAlign: "left",
													padding: "0.2rem 0.9rem 0rem 0.4rem",
												}}
											>
												{ageError}
											</Typography>
										)}
									</Grid>

									<Grid item xs={12} sm={6}>
										<CustomDropDown
											list={states}
											value={selectedState}
											setFunc={handleSelectedState}
											placeHolder="Select State"
										></CustomDropDown>
										{stateError && (
											<Typography
												color={red}
												variant="body2"
												display="block"
												style={{
													fontSize: "0.7rem",
													width: "100%",
													textAlign: "left",
													padding: "0.2rem 0.8rem 0rem 0.8rem",
												}}
											>
												{stateError}
											</Typography>
										)}
									</Grid>
									<Grid item xs={12} sm={6}>
										<CustomDropDown
											list={districts}
											value={selectedDistrict}
											setFunc={handleSelectedDistrict}
											placeHolder="Select District"
										></CustomDropDown>
										{districtError && (
											<Typography
												color={red}
												variant="body2"
												display="block"
												style={{
													fontSize: "0.7rem",
													width: "100%",
													textAlign: "left",
													padding: "0.2rem 0.8rem 0rem 0.8rem",
												}}
											>
												{districtError}
											</Typography>
										)}
									</Grid>

									<Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
										<Typography
											style={{
												fontSize: "0.9rem",
												color: maroon,
												fontWeight: 600,
											}}
										>
											Referral Details
										</Typography>
									</Grid>

									<Grid item xs={6} sm={6}>
										<CustomTextField
											placeHolder="Refer by"
											value={refreeName}
											setFunc={handleRefreeName}
										></CustomTextField>
										{refreeNameError && (
											<Typography
												color={red}
												variant="body2"
												display="block"
												style={{
													fontSize: "0.7rem",
													width: "100%",
													textAlign: "left",
													padding: "0.2rem 0.8rem 0rem 0.8rem",
												}}
											>
												{refreeNameError}
											</Typography>
										)}
									</Grid>

									<Grid item xs={6} sm={6}>
										<CustomTextField
											placeHolder="Refree Contact"
											value={refreeContact}
											setFunc={handleRefreeContact}
										></CustomTextField>
										{refreeContactError && (
											<Typography
												color={red}
												variant="body2"
												display="block"
												style={{
													fontSize: "0.7rem",
													width: "100%",
													textAlign: "left",
													padding: "0.2rem 0.8rem 0rem 0.8rem",
												}}
											>
												{refreeContactError}
											</Typography>
										)}
									</Grid>
								</Grid>
								<Box style={{ padding: "1rem 0rem 0rem 0rem" }}>
									<Button style={buttonStyle} onClick={handleAddLead}>
										Add
									</Button>
								</Box>
								{apiError && (
									<Typography
										color={red}
										variant="body2"
										display="block"
										style={{
											fontSize: "0.8rem",
											width: "100%",
											textAlign: "center",
											padding: "0.4rem 0.5rem 0rem 0.5rem",
											fontWeight: 600,
										}}
									>
										{apiError}
									</Typography>
								)}
							</Box>
						)}
					</Paper>
				</div>
			</div>
		</>
	);
}

export default ShareReferralForm;
