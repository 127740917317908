import { Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { headingBlue, maroon } from "../assets/colors";
import { useNavigate } from "react-router-dom";
import { Dashboard, SummarizeOutlined } from "@mui/icons-material";
import { containerStyle, imgContainer, imgStyle } from "./formStyle";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";

var rowStyle = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	color: headingBlue,
	curser: "pointer",
};

var headingStyle = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	wordWrap: "break-word",
	textAlign: "center",
	fontSize: "0.9rem",
};

const HomePage = () => {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	const navigate = useNavigate();

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 768);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const gridColumnStyle = isMobile ? "repeat(1, 1fr)" : "repeat(4, 1fr)";

	return (
		<div style={{ ...containerStyle, maxWidth: "32rem" }}>
			<div style={imgContainer}>
				<img
					src={require("../assets/images/WORK_logo.png")}
					alt="Logo"
					style={imgStyle}
				></img>
			</div>
			<Paper
				style={{
					display: "grid",
					gap: isMobile ? "1rem" : "1rem 0rem",
					gridTemplateColumns: gridColumnStyle,
					// alignItems: "top",
					padding: isMobile ? "2rem 0rem" : "1.5rem 1.5rem 1.5rem 1.5rem",
				}}
			>
				<div
					style={rowStyle}
					onClick={() => {
						const url = `${window.location.origin}/dsf`;
						window.open(url, "_blank", "noopener,noreferrer");
					}}
					onMouseOver={(e) => (e.currentTarget.style.color = maroon)}
					onMouseOut={(e) => (e.currentTarget.style.color = headingBlue)}
				>
					<SummarizeOutlined sx={{ fontSize: "3rem" }} />
					<Typography style={headingStyle}>District Selection Form</Typography>
				</div>
				<div
					style={{ ...rowStyle }}
					onClick={() => {
						const url = `${window.location.origin}/reportForm`;
						window.open(url, "_blank", "noopener,noreferrer");
						// navigate("/reportForm")
					}}
					onMouseOver={(e) => (e.currentTarget.style.color = maroon)}
					onMouseOut={(e) => (e.currentTarget.style.color = headingBlue)}
				>
					<SummarizeOutlined sx={{ fontSize: "3rem" }} />
					{/* <h3 style={{ fontSize: isMobile ? "1.1rem" : "1.2rem" }}>Tasks</h3> */}
					<Typography style={headingStyle}>Tasks Module</Typography>
				</div>
				<div
					style={rowStyle}
					onClick={() => {
						const url = `${window.location.origin}/content-sheet`;
						window.open(url, "_blank", "noopener,noreferrer");
						// navigate("/dashboard")
					}}
					onMouseOver={(e) => (e.currentTarget.style.color = maroon)}
					onMouseOut={(e) => (e.currentTarget.style.color = headingBlue)}
				>
					<DescriptionOutlinedIcon sx={{ fontSize: "3rem" }} />
					{/* <h3 style={{ fontSize: isMobile ? "1.1rem" : "1.2rem" }}>Tasks</h3> */}
					<Typography style={headingStyle}>Daily Task Content Sheet</Typography>
				</div>
				<div
					style={rowStyle}
					onClick={() => {
						const url = `${window.location.origin}/shareReferral`;
						window.open(url, "_blank", "noopener,noreferrer");
						// navigate("/dashboard")
					}}
					onMouseOver={(e) => (e.currentTarget.style.color = maroon)}
					onMouseOut={(e) => (e.currentTarget.style.color = headingBlue)}
				>
					<ConnectWithoutContactIcon style={{ fontSize: "3rem" }} />
					{/* <h3 style={{ fontSize: isMobile ? "1.1rem" : "1.2rem" }}>Tasks</h3> */}
					<Typography style={headingStyle}>Share Referral</Typography>
				</div>
				<div
					style={rowStyle}
					onClick={() => {
						const url = `${window.location.origin}/dashboard`;
						window.open(url, "_blank", "noopener,noreferrer");
						// navigate("/dashboard")
					}}
					onMouseOver={(e) => (e.currentTarget.style.color = maroon)}
					onMouseOut={(e) => (e.currentTarget.style.color = headingBlue)}
				>
					<Dashboard sx={{ fontSize: "3rem" }} />
					{/* <h3 style={{ fontSize: isMobile ? "1.1rem" : "1.2rem" }}>Tasks</h3> */}
					<Typography style={headingStyle}>Dashboard</Typography>
				</div>
			</Paper>
		</div>
	);
};

export default HomePage;
