import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { headingBlue } from '../assets/colors'

const TopCard = (props) => {
	
	var paperStyle={
		height: "19vh",
		border: props?.isSelected ? `1.5px solid ${headingBlue}` :`1px solid #D9D9D9`
	}
	var boxStyle={
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '42%',
		backgroundColor: props?.color ? props?.color : headingBlue,
		// border: '1px solid #2F4B82',
		borderRadius: "3px 3px 0px 0px",
	}

	var numberBoxStyle = {
		height: '58%',
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
		alignItems: 'center'
	}

	var headingstyle= {
		textAlign: 'center',
		verticalAlign: 'center',
		color: 'white',
		fontSize: "1.1rem",
		padding: '0.3rem 0.3rem 0.1rem 0.3rem',
		lineHeight: "1.4rem"
	}

	var numberHeadingstyle= {
		textAlign: 'center',
		verticalAlign: 'center',
		color: props?.color ? props?.color : headingBlue,
		fontSize: "2rem",
		padding: '0.3rem 0.3rem 0.3rem 0.3rem'
	}

  return (
		<Box style={{width: '100%'}}>
			<Paper style={paperStyle}>
			<Box style={boxStyle}>
				<Typography style={headingstyle}>{props.heading}</Typography>
			</Box>
			<Box style={numberBoxStyle}>
				<Typography style={numberHeadingstyle}>{props.data ? props.data : props.data === 0 ? 0 : '-'}</Typography>
			</Box>
    </Paper>

		</Box>
    
  )
}

export default TopCard