import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material'
import React from 'react'
import { headingBlue, lightGrey, maroon, mediumGrey, textColor } from '../assets/colors'
import { ExpandLess, ExpandMore, FormatLineSpacing, KeyboardArrowLeft, KeyboardArrowRight, WrongLocationIcon } from '@mui/icons-material'
import CustomDropDown from './CustomDropDown'
import CustomTextField from './CustomTextField'
import { tableFilterOption, tableOffsets } from '../utils/constants'
import DownloadDialogBox from './DownloadDialogBox'
import ConfirmationDialogBox from './confirmationDialogBox'
import MemberEditDialog from './MemberEditDialog'

const WORKersTable = (props) => {
  const [dialogStatus, setDialogStatus] = React.useState(false)
  const [leaderDialogStatus, setLeaderDialogStatus] = React.useState(false)
  const [confirmationMessage, setConfirmationMessage] = React.useState('')
  const [leaderContactNumber, setLeaderContactNumber] = React.useState(null)
  const [leaderIsLeader, setLeaderIsLeader] = React.useState(null)
  const [isNoActionDialog, setIsNoActionDialog] = React.useState(false)
  const [operation, setOperation] = React.useState('default')
  const [editDialogStatus, setEditDialogStatus] = React.useState(false)
  const [rowData, setRowData] = React.useState(null)
  const updateDownloadDialogStatus = (status) => {
    setDialogStatus(status)
  }

  const updateEditDailogStatus = (status, rowInfo, refresh) => {
    setRowData(rowInfo)
    setEditDialogStatus(status)
    if(refresh){
      props.refreshTableApi()
    }
  }

  const updateLeaderDialogStatus = (status, msg, contactNumber, isLeader, noActionDialog, refresh = false) => {
    setLeaderDialogStatus(status)
    setConfirmationMessage(msg)
    setLeaderContactNumber(contactNumber)
    setLeaderIsLeader(isLeader)
    setIsNoActionDialog(noActionDialog)
    if( refresh){
      props.refreshTableApi()
    }
  }

  var outerBoxStyle= {
    width: '100%',
    height: '52vh',
    marginTop: "0.6rem"
  }

  var paperStyle={
    // width: "100%",
    // height: '100%',
    border: '1px solid #D9D9D9',
    padding: "0.2rem 0.1rem 0.1rem 0.1rem",
  }

  var typographyStyle= {
    marginLeft: "0.5rem",
    padding:"0.1rem",
    fontSize: "1rem",
    fontWeight: "600"
  }

  var headerBoxStyle = {
    display: 'flex',
    padding: "0.3rem",
    flexWrap: 'wrap',
  }

  var internalBoxStyle= {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '20%',
    flex: '1 1',
    flexDirection: "row",
    flexWrap: 'wrap'
    // padding: "0.1rem 2rem"
  }

  var buttonStyle={
    backgroundColor: headingBlue,
    color: 'white',
    // marginRight: '0.2rem',
    marginLeft: "0.7rem",
    fontFamily: "Helvetica",
    fontWeight: '300',
    fontSize: '0.9rem',
    padding: "0.2rem 1rem 0.2rem 1rem"
  }

  var tableHeaderStyle={
    backgroundColor: mediumGrey,
  }

  var headerRowStyle= {
    // display: 'flex', 
    // justifyContent: 'space-between'
  }

  var headerCellStyle = {
    padding: '0.2rem 0.5rem 0.2rem 0.5rem', 
    fontSize: "0.9rem",
    fontWeight: "400",
    textAlign: 'center'
  }

  var iconBoxStyle = { 
    display: 'flex', 
    alignItems: 'center', 
    flexDirection: 'column', 
    padding: "0rem",
  }

  var iconStyle = {
    padding: "0rem 0rem 0rem 0.2rem",
    // marginBottom: '-0.3rem',
    // border: '1px solid'
  }

  return (
    <>
    <Box style={outerBoxStyle}>
        <Paper style={paperStyle}>
          <Box style={{...headerBoxStyle}}>
            <Box style={internalBoxStyle}>
              <Typography style={typographyStyle}> WORKers Table</Typography>
            </Box>
            <Box style={{ display:'flex', flex: "1 1", width: "80%", justifyContent: 'flex-end', padding: "0rem 0.5rem 0rem 0.5rem", flexWrap: 'wrap', gap: '0.5rem'}}>
            <CustomDropDown key='tableFilters' width="11rem" list={tableFilterOption} value={props.filterOnValue} placeHolder='Filter On' setFunc={props.updateFilterOn} regular={true}></CustomDropDown>
            <CustomTextField placeHolder="Enter Text to Filter" value={props.filterByValue} setFunc={props.updateFilterBy}></CustomTextField>
            <Button variant='outlined' sx={buttonStyle} style={{ textTransform: 'capitalize' }} onClick={updateDownloadDialogStatus}>Download</Button>
            </Box>
            
          </Box>
          <Box style={{display: 'flex', justifyContent: 'center', overflow: 'auto'}}>



    <TableContainer component={Paper} style={{width: '98%', height: "38vh", border: '1px solid #D9D9D9', marginTop: "0.2rem", "&::-webkit-scrollbar":{
                    display: 'none'
                  },
                  scrollbarWidth: "none"}}>
      <Table stickyHeader>
        <TableHead style={{backgroundColor: mediumGrey}}>
          <TableRow style={{backgroundColor: "blue", padding: '0rem 0rem 0rem 0rem'}}>
            <TableCell  style={{...headerCellStyle, backgroundColor: lightGrey}}>
              <Box style={{display: 'flex', justifyContent: 'center', alignItems :'center', padding :'0rem',color: headingBlue}}>
                WORKer
                <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <IconButton style={{...iconStyle, marginBottom: '-0.2rem',color: headingBlue}} size='small' onClick={() => {
                  props.updateSort('name', 1)
                }}><ExpandLess fontSize="small"/></IconButton>
                <IconButton style={{...iconStyle, marginTop: '-0.2rem',color: headingBlue}} size='small' onClick={() => {
                  props.updateSort('name', -1)
                }}><ExpandMore fontSize="small"/></IconButton>
                </Box>
              </Box>
            </TableCell>
            <TableCell  style={{...headerCellStyle, backgroundColor:  lightGrey}}>
              <Box style={{display: 'flex',justifyContent: 'center', alignItems :'center', fontSize: '0.85rem', lineHeight: '1rem' }}>
                Gender <br></br> Contact Number
                {/* <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}} onClick={() => {
                  props.updateSort('gender', 1)
                }}><ExpandLess fontSize="small"/></IconButton>
                <IconButton style={{...iconStyle, marginTop: '-0.2rem'}} onClick={() => {
                  props.updateSort('gender', -1)
                }}><ExpandMore fontSize="small"/></IconButton>
                </Box> */}
              </Box>
            </TableCell>
            <TableCell  style={{...headerCellStyle, backgroundColor:  lightGrey}}>
              <Box style={{display: 'flex',justifyContent: 'center', alignItems :'center', alignItems: 'center' }}>
                    District & Chapter
                {/* <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}} onClick={() => {
                  props.updateSort('district', 1)
                }}><ExpandLess fontSize="small"/></IconButton>
                <IconButton style={{...iconStyle, marginTop: '-0.2rem'}} onClick={() => {
                  props.updateSort('district', -1)
                }}><ExpandMore fontSize="small"/></IconButton>
                </Box> */}
              </Box>
            </TableCell>
            <TableCell  style={{...headerCellStyle, backgroundColor:  lightGrey}}>
              <Box style={{display: 'flex',justifyContent: 'center', alignItems :'center', fontSize: '0.85rem', lineHeight: '1rem' }}>
              Selected <br></br> District & State
                {/* <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}} onClick={() => {
                  props.updateSort('selectedDistrict', 1)
                }}><ExpandLess fontSize="small"/></IconButton>
                <IconButton style={{...iconStyle, marginTop: '-0.2rem'}} onClick={() => {
                  props.updateSort('selectedDistrict', -1)
                }}><ExpandMore fontSize="small"/></IconButton>
                </Box> */}
              </Box>
            </TableCell>
            <TableCell  style={{...headerCellStyle, backgroundColor:  lightGrey}}>
              <Box style={{display: 'flex', justifyContent: 'center', alignItems :'center', fontSize: '0.85rem', lineHeight: '1rem' }}>
                Selected <br></br>
                District & State
                {/* <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}} onClick={() => {
                  props.updateSort('secondSelectedDistrict', 1)
                }}><ExpandLess fontSize="small"/></IconButton>
                <IconButton style={{...iconStyle, marginTop: '-0.2rem'}} onClick={() => {
                  props.updateSort('secondSelectedDistrict', -1)
                }}><ExpandMore fontSize="small"/></IconButton>
                </Box> */}
              </Box>
            </TableCell>

            <TableCell  style={{...headerCellStyle, backgroundColor:  lightGrey}}>
              <Box style={{display: 'flex', justifyContent: 'center', alignItems :'center' }}>
                Alliance
                <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}} onClick={() => {
                  props.updateSort('allianceName', 1)
                }}><ExpandLess fontSize="small"/></IconButton>
                <IconButton style={{...iconStyle, marginTop: '-0.2rem'}} onClick={() => {
                  props.updateSort('allianceName', -1)
                }}><ExpandMore fontSize="small"/></IconButton>
                </Box>
              </Box>
            </TableCell>
            <TableCell  style={{...headerCellStyle, backgroundColor:  lightGrey}}>
              <Box style={{display: 'flex', justifyContent: 'center', alignItems :'center', fontSize: '0.8rem', lineHeight: '1rem' }}>
                Team Leader & <br></br>Supervisor
                {/* <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}} onClick={() => {
                  props.updateSort('secondSelectedDistrict', 1)
                }}><ExpandLess fontSize="small"/></IconButton>
                <IconButton style={{...iconStyle, marginTop: '-0.2rem'}} onClick={() => {
                  props.updateSort('secondSelectedDistrict', -1)
                }}><ExpandMore fontSize="small"/></IconButton>
                </Box> */}
              </Box>
            </TableCell>

            
            {/* <TableCell  style={headerCellStyle}>
              <Box style={{display: 'flex', justifyContent: 'center', alignItems :'center' }}>
                Reciept Number
                <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}} onClick={() => {
                  props.updateSort('recieptNumber', 1)
                }}><ExpandLess fontSize="small"/></IconButton>
                <IconButton style={{...iconStyle, marginTop: '-0.2rem'}} onClick={() => {
                  props.updateSort('recieptNumber', -1)
                }}><ExpandMore fontSize="small"/></IconButton>
                </Box>
              </Box>
            </TableCell> */}
            {props.designation === 'admin' &&
              <TableCell  style={{...headerCellStyle, backgroundColor:  lightGrey}}>
                <Box style={{display: 'flex', justifyContent: 'center', alignItems :'center' }}>
                  Actions
                  {/* <Box style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                  <IconButton style={{...iconStyle, marginBottom: '-0.2rem'}} onClick={() => {
                    props.updateSort('amountPaid', 1)
                  }}><ExpandLess fontSize="small"/></IconButton>
                  <IconButton style={{...iconStyle, marginTop: '-0.2rem'}} onClick={() => {
                    props.updateSort('amountPaid', -1)
                  }}><ExpandMore fontSize="small"/></IconButton>
                  </Box> */}
                </Box>
              </TableCell>
            }
            
          </TableRow>
        </TableHead>
        <TableBody style={{ textAlign: 'center', overflowY: 'auto'}}>
          {props?.data?.records?.map((row, index) => { return (
            <TableRow key={index}>
              {row.isLeader ? 
                <TableCell  style={{...headerCellStyle,color: headingBlue}}>{row.name ? row.name + ' (TL)' : '-'}</TableCell> :
                <TableCell  style={{...headerCellStyle,color: headingBlue}}>{row.name ? row.name  : '-'}</TableCell>
              }
              
              <TableCell  style={{...headerCellStyle, fontSize: '0.8rem'}}>{row.gender ? row.gender : '-'}<br></br>{row.contactNumber ? row.contactNumber : '-'}</TableCell>
              <TableCell  style={{...headerCellStyle, fontSize: '0.8rem'}}>{row.district ? row.district : '-'}<br></br>{row.chapter ? row.chapter : '-'}</TableCell>
              <TableCell  style={{...headerCellStyle, fontSize: '0.8rem'}}>{row.selectedDistrict ? row.selectedDistrict : '-'}<br></br> {row.selectedChapter ? row.selectedChapter : '-'}</TableCell>
              <TableCell  style={{...headerCellStyle, fontSize: '0.8rem'}}>{row.secondSelectedDistrict ? row.secondSelectedDistrict : '-'}<br></br>{row.secondSelectedChapter ? row.secondSelectedChapter : '-'}</TableCell>
              <TableCell  style={{...headerCellStyle, fontSize: '0.8rem'}}>{row.allianceName ? row.allianceName : '-'}</TableCell>
              <TableCell  style={{...headerCellStyle, fontSize: '0.8rem'}}>{row.leaderName ? row.leaderName : '-'}<br></br>{row.supervisorName ? row.supervisorName : '-'}</TableCell>
              {/* <TableCell  style={headerCellStyle}>{row.feePaidDate ? (new Date(row.feePaidDate)).toLocaleString("en-In", {
                timeZone: "Asia/Kolkata",
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }).replace(/\//g, '-') : '-'}</TableCell> */}
              {/* <TableCell  style={headerCellStyle}>{row.recieptNumber ? row.recieptNumber : '-'}</TableCell> */}
              {props.designation === 'admin' &&
                <TableCell  style={headerCellStyle}>
                <Box style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '0.4rem'}}>
                  {row.isLeader ? 
                    <Paper style={{width: '17px', height: '17px', padding: '0.2rem 0.25rem 0.25rem 0.25rem'}}
                      onClick={() => 
                        row.isAllianced ? 
                        updateLeaderDialogStatus(true, `You cannot remove"${row.name}" as Team Leader, as the WORKer is allied in ${row.allianceName} alliance.`, row.contactNumber, false, true) :
                        updateLeaderDialogStatus(true, `Are you sure you want to remove "${row.name}" as leader.`, row.contactNumber, false, false)}
                    >
                    <img src={require('../assets/images/leader.png')} style={{width: "18px", height: "18px"}}></img>
                    </Paper> :
                    <Paper style={{width: '17px', height: '17px', padding: '0.2rem 0.25rem 0.25rem 0.25rem'}}
                    onClick={() => 
                      row.isAllianced ? 
                      updateLeaderDialogStatus(true, `You cannot make"${row.name}" as Team Leader, as the WORker is allied in ${row.allianceName} alliance.`, row.contactNumber, true, true) :
                      updateLeaderDialogStatus(true, `Are you sure you want to make "${row.name}" a leader.`, row.contactNumber, true, false)}
                    >
                    <img src={require('../assets/images/notLeader.png')} style={{width: "18px", height: "18px"}}></img>
                    </Paper>
                  }
                
                  <Paper style={{width: '17px', height: '17px', padding: '0.2rem 0.2rem 0.25rem 0.2rem'}}>
                    {
                      row.isLeader ? 
                        <img src={require('../assets/images/disable_reset_password.png')} style={{width: "18px", height: "18px", pointerEvents: 'none', cursor: 'not-allowed'}}
                        ></img>
                      :
                        <img src={require('../assets/images/reset_password.png')} style={{width: "18px", height: "18px"}}
                          onClick={() =>{
                            setOperation('delete')
                            updateLeaderDialogStatus(true, 'Are You Sure You want to Delete Member\'s Login Account.', row.contactNumber, false, false, true)
                          }}
                        ></img>


                    }
                  
                  </Paper>
                  {(row?.['selectedDistrict'] || row?.['secondSelectedDistrict']) && !row.isLeader ? (
                        <Paper style={{width: '17px', height: '17px', padding: '0.2rem 0.2rem 0.25rem 0.2rem'}}
                        onClick={() =>
                          updateEditDailogStatus(true, row)
                        }
                      >
                      <img src={require('../assets/images/edit_icon.png')} style={{width: "18px", height: "18px"}}></img>
                      </Paper>
                    ) : (
                      <Paper style={{width: '17px', height: '17px', padding: '0.2rem 0.2rem 0.25rem 0.2rem', pointerEvents: 'none', opacity: '0.5'}}
                        >
                        <img src={require('../assets/images/edit_icon.png')} style={{width: "18px", height: "18px"}}></img>
                        </Paper>
                    )
                  }
                  
                  
                  
                  <Paper style={{width: '17px', height: '17px', padding: '0.2rem 0.2rem 0.25rem 0.2rem'}}>
                  <img src={require('../assets/images/delete_icons.png')} style={{width: "18px", height: "18px"}}></img>
                  </Paper>
                </Box>
                </TableCell>
              }
            </TableRow>
          )
          })
        }
        </TableBody>
        
      </Table>
    </TableContainer>  
    </Box>
    
    <Box style={{display: 'flex', justifyContent: 'center',padding: "0.4rem 0rem 0.1rem 0rem"}}>
      <Box style={{width: "30%", display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: "0rem 0.5rem 0rem 0.9rem"}}>
          <Typography style={{color: maroon, fontSize: '0.9rem'}}>
            {((props?.pageValue - 1) * props?.offsetValue) +1} - {props?.pageValue * props?.offsetValue} of {props?.data?.totalRecords}
          </Typography>
      </Box>
      <Box style={{width: '40%',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <IconButton size='small'
          onClick={() => {
            if(props.pageValue > 1){
              props.updatePage(props.pageValue - 1)
            }
          }}
          disabled={props.pageValue === 1}
        ><KeyboardArrowLeft size='small' sx= {{color: props.pageValue === 1 ? mediumGrey: headingBlue}}
        /></IconButton>
        <Button variant='contained' style={{minWidth: '0rem', width: '1.5rem', height: '1.5rem',borderRadius: '50%', backgroundColor: headingBlue, color: 'white', padding: '0rem', alignItems: 'center'}}>{props.pageValue}</Button>
        <IconButton size='small'
          onClick={() => {
            if(props.pageValue < props.data.totalPages){
              props.updatePage(props.pageValue + 1)
            }
          }}
          disabled={props.pageValue === props.data.totalPages}
        ><KeyboardArrowRight size='small' sx= {{color: (props.pageValue === props.data.totalPages || props.data.totalPages === 0) ? mediumGrey: headingBlue}}
        /></IconButton>

      </Box>
      <Box style={{width: '30%',display: 'flex', justifyContent: 'flex-end', padding: "0rem 0.6rem 0rem 0rem"}}>
        <CustomDropDown key='table' value={props.offsetValue} width="5rem" setFunc={props.updateOffset} list={tableOffsets} regular={true}></CustomDropDown>
      </Box>

    </Box>
    </Paper>
    </Box>

    <DownloadDialogBox dialogStatus= {dialogStatus} updateDownloadDialogStatus={updateDownloadDialogStatus}  data={props?.data?.records} dashBoard={props.dashBoard}></DownloadDialogBox>
    <ConfirmationDialogBox leaderDialogStatus={leaderDialogStatus} updateLeaderDialogStatus={updateLeaderDialogStatus} confirmationMsg={confirmationMessage} contactNumber={leaderContactNumber} isLeader={leaderIsLeader} isNoActionDialog={isNoActionDialog} operation={operation}></ConfirmationDialogBox>
    <MemberEditDialog dialogStatus={editDialogStatus} updateDialog={updateEditDailogStatus} data={rowData}></MemberEditDialog>
    </>
    
  )
}

export default WORKersTable