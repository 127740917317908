export const mediumGrey = "#D9D9D9";
// export const headingBlue = '#2F4B82'
// export const headingBlue = '#072d6d'
// export const headingBlue = '#1d4c9c'
export const headingBlue = "#294b82";
export const textColor = "#1800EE";
export const red = "#F7133C";
export const pureWhite = "#fff";
export const yellowish = "#e6a500";
export const lightGrey = "#FBFBFB";
export const maroon = "#dc3545";
export const lightRed = "#ffe6e6";
export const Donegreen = "#ccffcc"
export const textGeen = "#008000"
export const pendingYellow = "#FFF5BA"
export const textYellow= "#3d2412"
export const amber = "#e6a500"
export const green = '#1b5e20'
export const bgRed = '#EE4B2B'
export const orange = '#ed683c'
export const hMapBlue = '#1d4877'
// export const textColor = '#1800EE'
