import React from "react";
import { headingBlue } from "../assets/colors";
import { imgContainer, imgStyle } from "./formStyle";
import { Link } from "react-router-dom";

const ReportFormSubmitMessage = (props) => {
	return (
		<div style={imgContainer}>
			<img
				src={require("../assets/images/right-icon.png")}
				alt="right-icon"
				style={{ ...imgStyle, width: "7rem", height: "7rem" }}
			></img>
			{props.message ? (
				<p style={{ color: headingBlue, fontWeight: "bold", fontSize: "1rem" }}>
					{props.message}
				</p>
			) : (
				<p style={{ color: headingBlue, fontWeight: "bold", fontSize: "1rem" }}>
					Alhamdulillah, your response has been recorded successfully!
				</p>
			)}
			{props.isLeadSubmission && (
				<div style={{ display: "flex", justifyContent: "center" }}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "0.5rem",
							fontSize: "0.8rem",
							color: headingBlue,
						}}
					>
						<p>Do you want to add another lead?</p>
						<Link
							to="/shareReferral"
							onClick={() => {
								props.setIsFormSubmitted(false);
								props.setApiError("");
							}}
						>
							Add New Lead
						</Link>
					</div>
				</div>
			)}
		</div>
	);
};

export default ReportFormSubmitMessage;
