import {
	FormControl,
	MenuItem,
	Select,
	styled,
	TextField,
	useMediaQuery,
} from "@mui/material";
import React, { Component } from "react";

function CustomDropDown(props) {
	const isMobile = useMediaQuery("(max-width:600px)");

	return props.regular ? (
		<>
			<Select
				variant="outlined"
				value={props.value}
				style={{
					textAlign: "left",
					width: props.width ? props.width : "6rem",
					color: props.placeHolder === props.value ? "#B0B0B0" : "black",
					height: props.height ? props.height : "2rem",
					padding: "0.1rem",
					paddingLeft: "0rem",
					boxShadow: "0 0.2rem 0.4rem 0 #D3D3D3",
					fontSize: isMobile ? "0.8rem" : "0.9rem",
				}}
				sx={{ fontSize: "0.9rem" }}
				MenuProps={{
					PaperProps: {
						style: {
							maxHeight: "184px",
							fontSize: "0.9rem",
							width: props.width ? props.width : "6rem",
							"&::-webkit-scrollbar": {
								display: "none",
							},
							scrollbarWidth: "none",
						},
					},
				}}
				onChange={(e) => {
					props.isCount
						? props.setFunc(e.target.value, props.count)
						: props.setFunc(e.target.value);
				}}
				displayEmpty
				renderValue={() => props.value}
			>
				{props.placeHolder && (
					<MenuItem value={props.value} disabled>
						{props.placeHolder}
					</MenuItem>
				)}

				{props.isValueRequired
					? props?.list?.map((option) => (
							<MenuItem key={option.name} value={option}>
								{option.name}
							</MenuItem>
					  ))
					: props.list.map((option) => (
							<MenuItem
								key={option.name}
								value={option.name}
								disabled={
									(props?.isDeletingLeader &&
										option.contactNumber === props.leaderContact) ||
									false
								}
							>
								{option.name}
							</MenuItem>
					  ))}
			</Select>
		</>
	) : (
		<>
			<Select
				fullWidth
				variant="outlined"
				value={props.value}
				style={{
					fontSize: "0.9rem",
					textAlign: "left",
					color: props.placeHolder === props.value ? "#B0B0B0" : "black",
					height: props.height ? props.height : "2rem",
					padding: "0.2rem",
					paddingLeft: "0rem",
					boxShadow: "0 0.2rem 0.4rem 0 #D3D3D3",
				}}
				sx={{ fontSize: "0.8rem" }}
				MenuProps={{
					PaperProps: {
						style: {
							maxHeight: "184px",
							fontSize: "0.9rem",
							width: props.width ? props.width : "6rem",
							"&::-webkit-scrollbar": {
								display: "none",
							},
							scrollbarWidth: "none",
						},
					},
				}}
				onChange={(e) =>
					props.isCount
						? props.setFunc(e.target.value, props.count)
						: props.setFunc(e.target.value)
				}
				displayEmpty
				renderValue={() => props.value}
			>
				{/* {props.placeHolder && (
					<MenuItem value={props.value ? props.value : "Filter On"} disabled>
						{props.placeHolder}
					</MenuItem>
				)} */}

				{props.placeHolder && (
					<MenuItem value={props.placeHolder} disabled>
						{props.placeHolder}
					</MenuItem>
				)}

				{props.isValueRequired
					? props?.list?.map((option) => (
							<MenuItem key={option.name} value={option}>
								{option.name}
							</MenuItem>
					  ))
					: props?.list?.map((option) => (
							<MenuItem
								key={option.name ? option.name : option}
								value={option.name ? option.name : option}
							>
								{option.name ? option.name : option}
							</MenuItem>
					  ))}
			</Select>
		</>
	);
}

export default CustomDropDown;
