import axios from "axios";
import { baseURL } from "./backendApis";

const token = localStorage.getItem("token");

const HttpService = async (options) => {
	try {
		let url = baseURL + options.url;
		if (options.method === "POST") {
			const response = await axios.post(
				url,
				options.payload || options.data,
				options.headers
			);
			return response;
		} else if (options.method === "GET") {
			const response = await axios.get(url, {
				params: options.params,
				headers: options.headers,
			});
			// console.log(" Got the response: ", response);
			return response;
		} else if (options.method === "DELETE") {
			const response = await axios.delete(url, {
				data: options.payload || options.data,
				headers: options.headers,
			});
			console.log(" Got the response: ", response);
			return response;
		} else if (options.method === "PUT") {
			const response = await axios.put(
				url, 
				options.payload || options.data, 
				{
				headers: options.headers,
			});
			console.log(" Got the response: ", response);
			return response;
		}
	} catch (err) {
		throw err;
	}
};

export default HttpService;
