import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Paper, Switch, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { headingBlue, maroon, red, yellowish } from '../assets/colors'
import CustomTextField from './CustomTextField'
import CustomDatePicker from './CustomDatePicker'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import dayjs from 'dayjs'
import { WidthFull } from '@mui/icons-material'
import { buttonStyle } from '../utils/commonCSS'
import zIndex from '@mui/material/styles/zIndex'
import { addDailyTasks, fetchDailyTasksList } from '../backend/backendApis'
import HttpService from '../backend/requestApis'
import { data } from '@remix-run/router'
import { errorStyleText } from './formStyle'
import { useNavigate } from 'react-router-dom'
import Loader from './Loader'

const twitterTaskDate = process.env.REACT_APP_TWITTER_TASK_DATE
const MEN_TWO_DISTRICTS_TASK_DATE = process.env.REACT_APP_MEN_TWO_DISTRICTS_TASK_DATE

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

function AddDailyTaskDialogBox(props) {

    dayjs.extend(utc);
	dayjs.extend(timezone);

    const navigate = useNavigate()

    const [toggle, setToggle] = React.useState('Men')
    const [selectedDate, setSelectedDate] = React.useState(dayjs())
    const [shareMuslimVideosOfAllamaLink, setShareMuslimVideosOfAllamaLink] = React.useState('')
    const [shareMuslimVideosOfAllamaLinkError, setShareMuslimVideosOfAllamaLinkError] = React.useState('')
    const [shareMuslimVideosOfAllamaCaption, setShareMuslimVideosOfAllamaCaption] = React.useState('')
    const [shareMuslimVideosOfAllamaCaptionError, setShareMuslimVideosOfAllamaCaptionError] = React.useState('')
    const [shareNonMuslimVideosOfAllamaLink, setShareNonMuslimVideosOfAllamaLink] = React.useState('')
    const [shareNonMuslimVideosOfAllamaLinkError, setShareNonMuslimVideosOfAllamaLinkError] = React.useState('')
    const [shareNonMuslimVideosOfAllamaCaption, setShareNonMuslimVideosOfAllamaCaption] = React.useState('')
    const [shareNonMuslimVideosOfAllamaCaptionError, setShareNonMuslimVideosOfAllamaCaptionError] = React.useState('')
    const [likeAndCommentOnFBPageLink, setLikeAndCommentOnFBPageLink] = React.useState('')
    const [likeAndCommentOnFBPageLinkError, setLikeAndCommentOnFBPageLinkError] = React.useState('')
    const [mentionIndividualsInCommentsLink, setMentionIndividualsInCommentsLink] = React.useState('')
    const [mentionIndividualsInCommentsLinkError, setMentionIndividualsInCommentsLinkError] = React.useState('')
    const [remixYTLink, setRemixYTLink] = React.useState('')
    const [remixYTLinkError, setRemixYTLinkError] = React.useState('')
    const [remixYTLinkD2, setRemixYTLinkD2] = React.useState('')
    const [remixYTLinkD2Error, setRemixYTLinkD2Error] = React.useState('')
    const [remixYTCaption, setRemixYTCaption] = React.useState('')
    const [remixYTCaptionError, setRemixYTCaptionError] = React.useState('')
    const [remixYTCaptionD2, setRemixYTCaptionD2] = React.useState('')
    const [remixYTCaptionD2Error, setRemixYTCaptionD2Error] = React.useState('')
    const [remixFBLink, setRemixFBLink] = React.useState('')
    const [remixFBLinkError, setRemixFBLinkError] = React.useState('')
    const [remixFBLinkD2, setRemixFBLinkD2] = React.useState('')
    const [remixFBLinkD2Error, setRemixFBLinkD2Error] = React.useState('')
    const [remixFBCaption, setRemixFBCaption] = React.useState('')
    const [remixFBCaptionError, setRemixFBCaptionError] = React.useState('')
    const [remixFBCaptionD2, setRemixFBCaptionD2] = React.useState('')
    const [remixFBCaptionD2Error, setRemixFBCaptionD2Error] = React.useState('')
    const [remixInstaLink, setRemixInstaLink] = React.useState('')
    const [remixInstaLinkError, setRemixInstaLinkError] = React.useState('')
    const [remixInstaLinkD2, setRemixInstaLinkD2] = React.useState('')
    const [remixInstaLinkD2Error, setRemixInstaLinkD2Error] = React.useState('')
    const [remixInstaCaption, setRemixInstaCaption] = React.useState('')
    const [remixInstaCaptionError, setRemixInstaCaptionError] = React.useState('')
    const [remixInstaCaptionD2, setRemixInstaCaptionD2] = React.useState('')
    const [remixInstaCaptionD2Error, setRemixInstaCaptionD2Error] = React.useState('')
    const [sharedVideosOnWALink, setSharedVideosOnWALink] = React.useState('')
    const [sharedVideosOnWALinkError, setSharedVideosOnWALinkError] = React.useState('')
    const [sharedVideosOnWACaption, setSharedVideosOnWACaption] = React.useState('')
    const [sharedVideosOnWACaptionError, setSharedVideosOnWACaptionError] = React.useState('')
    const [sharedDarsLink, setSharedDarsLink] = React.useState('')
    const [sharedDarsLinkError, setSharedDarsLinkError] = React.useState('')
    const [reTweetVideoLink, setReTweetVideoLink] = React.useState('')
    const [reTweetVideoLinkError, setReTweetVideoLinkError] = React.useState('')
    // const [reTweetVideoCaption, setReTweetVideoCaption] = React.useState('')
    const [reTweetVideoCaptionError, setReTweetVideoCaptionError] = React.useState('')

    const [apiError, setApiError] = React.useState('')
    const [isLoader, setIsLoader] = React.useState(false)

    var titleStyle={
        backgroundColor: headingBlue,
        color: 'white',
        fontSize:'1rem',
        display: "flex",
        alignItems: 'center'
      }

    const updateSelectedDate = (date) => {
        setSelectedDate(date)
    }

    const handleAdd = () => {
        try{
            // setIsLoader(true)
            let isValid = true

            if(!shareMuslimVideosOfAllamaLink){
                isValid = false
                setShareMuslimVideosOfAllamaLinkError('Link is required')
            } else {
                setShareMuslimVideosOfAllamaLinkError('')
            }

            if(!shareMuslimVideosOfAllamaCaption){
                isValid = false
                setShareMuslimVideosOfAllamaCaptionError('Caption is required')
            } else {
                setShareMuslimVideosOfAllamaCaptionError('')
            }

            if(!shareNonMuslimVideosOfAllamaLink){
                isValid = false
                setShareNonMuslimVideosOfAllamaLinkError('Link is required')
            } else {
                setShareNonMuslimVideosOfAllamaLinkError('')
            }

            if(!shareNonMuslimVideosOfAllamaCaption){
                isValid = false
                setShareNonMuslimVideosOfAllamaCaptionError('Caption is required')
            } else {
                setShareNonMuslimVideosOfAllamaCaptionError('')
            }

            if( !likeAndCommentOnFBPageLink){
                isValid = false
                setLikeAndCommentOnFBPageLinkError('Link is Required')
            } else {
                setLikeAndCommentOnFBPageLinkError('')
            }

            if( !mentionIndividualsInCommentsLink){
                isValid = false
                setMentionIndividualsInCommentsLinkError('Link is Required')
            } else {
                setMentionIndividualsInCommentsLinkError('')
            }

            if( !remixYTLink){
                isValid = false
                setRemixYTLinkError('Link is Required')
            } else {
                setRemixYTLinkError('')
            }

            if( !remixYTLinkD2 && toggle === 'Women'){
                isValid = false
                setRemixYTLinkD2Error('Link is Required')
            } else {
                setRemixYTLinkD2Error('')
            }

            if( !remixYTCaption){
                isValid = false
                setRemixYTCaptionError('Caption is Required')
            } else {
                setRemixYTCaptionError('')
            }

            if( !remixYTCaptionD2 && toggle === 'Women'){
                isValid = false
                setRemixYTCaptionD2Error('Caption is Required')
            } else {
                setRemixYTCaptionD2Error('')
            }

            if( !remixFBLink){
                isValid = false
                setRemixFBLinkError('Link is Required')
            } else {
                setRemixFBLinkError('')
            }

            if( !remixFBLinkD2 && toggle === 'Women'){
                isValid = false
                setRemixFBLinkD2Error('Link is Required')
            } else {
                setRemixFBLinkD2Error('')
            }

            if( !remixFBCaption){
                isValid = false
                setRemixFBCaptionError('Caption is Required')
            } else {
                setRemixFBCaptionError('')
            }

            if( !remixFBCaptionD2 && toggle === 'Women'){
                isValid = false
                setRemixFBCaptionD2Error('Caption is Required')
            } else {
                setRemixFBCaptionD2Error('')
            }

            if( !remixInstaLink){
                isValid = false
                setRemixInstaLinkError('Link is Required')
            } else {
                setRemixInstaLinkError('')
            }

            if( !remixInstaLinkD2 && toggle === 'Women'){
                isValid = false
                setRemixInstaLinkD2Error('Link is Required')
            } else {
                setRemixInstaLinkD2Error('')
            }

            if( !remixInstaCaption){
                isValid = false
                setRemixInstaCaptionError('Caption is Required')
            } else {
                setRemixInstaCaptionError('')
            }

            if( !remixInstaCaptionD2 && toggle === 'Women'){
                isValid = false
                setRemixInstaCaptionD2Error('Caption is Required')
            } else {
                setRemixInstaCaptionD2Error('')
            }

            if( !sharedVideosOnWALink){
                isValid = false
                setSharedVideosOnWALinkError('Link is Required')
            } else {
                setSharedVideosOnWALinkError('')
            }

            if( !sharedVideosOnWACaption){
                isValid = false
                setSharedVideosOnWACaptionError('Caption is Required')
            } else {
                setSharedVideosOnWACaptionError('')
            }

            if( !sharedDarsLink){
                isValid = false
                setSharedDarsLinkError('Link is Required')
            } else {
                setSharedDarsLinkError('')
            }
            if( !reTweetVideoLink){
                isValid = false
                setReTweetVideoLink('Link is Required')
            } else {
                setReTweetVideoLink('')
            }

            // if( !reTweetVideoCaption){
            //     isValid = false
            //     setReTweetVideoCaptionError('Caption is Required')
            // } else {
            //     setReTweetVideoCaptionError('')
            // }
            if(isValid){

                let options = {
                    url: addDailyTasks,
                    method: "POST",
                    payload: {
                        taskDate: dayjs(dayjs(dayjs(selectedDate).format()).utc()).tz('Asia/Kolkata').format("YYYY-MM-DD"),
                        gender: toggle,
                        shareMuslimVideosOfAllamaLink: shareMuslimVideosOfAllamaLink,
                        shareMuslimVideosOfAllamaCaption: shareMuslimVideosOfAllamaCaption,
                        shareNonMuslimVideosOfAllamaLink: shareNonMuslimVideosOfAllamaLink,
                        shareNonMuslimVideosOfAllamaCaption: shareNonMuslimVideosOfAllamaCaption,
                        likeAndCommentOnFBPageLink: likeAndCommentOnFBPageLink,
                        mentionIndividualsInCommentsLink: mentionIndividualsInCommentsLink,
                        remixYTLink: remixYTLink,
                        remixYTLinkD2: remixYTLinkD2,
                        remixYTCaption: remixYTCaption,
                        remixYTCaptionD2: remixYTCaptionD2,
                        remixFBLink: remixFBLink,
                        remixFBLinkD2: remixFBLinkD2,
                        remixFBCaption: remixFBCaption,
                        remixFBCaptionD2:remixFBCaptionD2,
                        remixInstaLink:remixInstaLink,
                        remixInstaLinkD2: remixInstaLinkD2,
                        remixInstaCaption: remixInstaCaption,
                        remixInstaCaptionD2: remixInstaCaptionD2,
                        sharedVideosOnWALink: sharedVideosOnWALink,
                        sharedVideosOnWACaption: sharedVideosOnWACaption,
                        sharedDarsLink: sharedDarsLink,
                        reTweetLink: reTweetVideoLink,
                        // reTweetCaption: reTweetVideoCaption
                    },
                    headers: {headers: {
                        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
                    }}
                }
                HttpService(options).then(() => {
                    setIsLoader(false)
                    props.updateTaskDialogStatus()
                }).catch(err => {
                    console.log("Error in adding task list: ", err)
                    if(err?.status === 403){
                        localStorage.clear()
                        setIsLoader(false)
                        navigate('/Login')
                    } else {
                        setIsLoader(false)
                        setApiError('Error in Adding tasks. Please Contact Support.')
                    }
                
                })
            } else {
                setIsLoader(false)
            }
        } catch(err){
            console.log("Error in Adding Daily Tasks: ", err)
            if(err?.status === 403){
                localStorage.clear()
                setIsLoader(false)
                navigate('/Login')
              } else {
                setIsLoader(false)
                setApiError('Error in Adding tasks. Please Contact Support.')
              }
        }
    }

    React.useEffect(() => {
        try{
            setIsLoader(true)
            setShareMuslimVideosOfAllamaLinkError('')
            setShareMuslimVideosOfAllamaCaptionError('')
            setShareNonMuslimVideosOfAllamaLinkError('')
            setShareNonMuslimVideosOfAllamaCaptionError('')
            setLikeAndCommentOnFBPageLinkError('')
            setMentionIndividualsInCommentsLinkError('')
            setRemixYTLinkError('')
            setRemixYTLinkD2Error('')
            setRemixYTCaptionError('')
            setRemixYTCaptionD2Error('')
            setRemixFBLinkError('')
            setRemixFBLinkD2Error('')
            setRemixFBCaptionError('')
            setRemixFBCaptionD2Error('')
            setRemixInstaLinkError('')
            setRemixInstaLinkD2Error('')
            setRemixInstaCaptionError('')
            setRemixInstaCaptionD2Error('')
            setSharedVideosOnWALinkError('')
            setSharedVideosOnWACaptionError('')
            setSharedDarsLinkError('')
            if( props.taskDialogStatus){
                let options = {
                    url: fetchDailyTasksList,
                    method: 'GET',
                    params: {
                        taskDate: dayjs(dayjs(dayjs(selectedDate).format()).utc()).tz('Asia/Kolkata').format("YYYY-MM-DD"),
                        gender: toggle
                    },
                    headers: {
                        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
                    }
                }
                HttpService(options).then(data =>{
                    data = data?.data?.data
                    setShareMuslimVideosOfAllamaLink(data['shareMuslimVideosOfAllamaLink'])
                    setShareMuslimVideosOfAllamaCaption(data['shareMuslimVideosOfAllamaCaption'])
                    setShareNonMuslimVideosOfAllamaLink(data['shareNonMuslimVideosOfAllamaLink'])
                    setShareNonMuslimVideosOfAllamaCaption(data['shareNonMuslimVideosOfAllamaCaption'])
                    setLikeAndCommentOnFBPageLink(data['likeAndCommentOnFBPageLink'])
                    setMentionIndividualsInCommentsLink(data['mentionIndividualsInCommentsLink'])
                    setRemixYTLink(data['remixYTLink'])
                    setRemixYTLinkD2(data['remixYTLinkD2'])
                    setRemixYTCaption(data['remixYTCaption'])
                    setRemixYTCaptionD2(data['remixYTCaptionD2'])
                    setRemixFBLink(data['remixFBLink'])
                    setRemixFBLinkD2(data['remixFBLinkD2'])
                    setRemixFBCaption(data['remixFBCaption'])
                    setRemixFBCaptionD2(data['remixFBCaptionD2'])
                    setRemixInstaLink(data['remixInstaLink'])
                    setRemixInstaLinkD2(data['remixInstaLinkD2'])
                    setRemixInstaCaption(data['remixInstaCaption'])
                    setRemixInstaCaptionD2(data['remixInstaCaptionD2'])
                    setSharedVideosOnWALink(data['sharedVideosOnWALink'])
                    setSharedVideosOnWACaption(data['sharedVideosOnWACaption'])
                    setSharedDarsLink(data['sharedDarsLink'])
                    setReTweetVideoLink(data['reTweetLink'])
                    // setReTweetVideoCaption(data['reTweetCaption'])
                    setIsLoader(false)
                }).catch(err =>{
                    if(err?.status === 403){
                        localStorage.clear()
                        setIsLoader(false)
                        navigate('/Login')
                    } else {
                        setIsLoader(false)
                        setApiError('Error in fetching Tasks. Please Contact support')
                    }

                })
            } else {
                setIsLoader(false)
            }
        }catch(err){
            if(err?.status === 403){
                localStorage.clear()
                setIsLoader(false)
                navigate('/Login')
              } else {
                setIsLoader(false)
                setApiError('Error in fetching Tasks. Please Contact support')
              }
        }
    }, [props.taskDialogStatus, toggle, selectedDate])


  return (
    <>
        <Dialog maxWidth='sm' fullWidth open={props.taskDialogStatus} onClose={() => props.updateTaskDialogStatus()}>
            <DialogTitle sx={{height: "1.8rem", padding: "0.4rem 0rem 0.4rem 1rem"  }}  style={titleStyle}>
                Add Tasks
            </DialogTitle>
            <DialogContent style={{ padding: "1rem 0.7rem 0.7rem 0.7rem"}}>
                <>
                {isLoader && 
                    <Loader></Loader>
                }
                <Box>
                    <Box style={{float: 'right'}}>
                        <CustomDatePicker selectedDate={selectedDate} setSelectedDate={updateSelectedDate} isNotRestricted={true}></CustomDatePicker>
                    </Box>
                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                        <ToggleButtonGroup
                            color="primary"
                            value={toggle}
                            exclusive
                            sx={{
                                display: "flex",
                                "& .MuiToggleButton-root": {
                                  fontSize: "0.9rem", // Smaller font
                                  padding: "4px 8px", // Smaller padding
                                  minWidth: "50px",   // Smaller width
                                  "&.Mui-selected": {
                                    backgroundColor: headingBlue, // Orange color for selected state
                                    color: "#fff",
                                    }
                                },
                              }}                        
                            onChange={(event) => {
                                setToggle(event.target.value)
                            }}
                        >
                            <ToggleButton value='Men'>Men</ToggleButton>
                            <ToggleButton value='Women'>Women</ToggleButton>

                        </ToggleButtonGroup>
                    </Box>
                </Box>
                <Paper style={{ width: "97%", height: '47vh', margin: "0.5rem 0rem 0.5rem 0rem", padding: '0.5rem', overflow:'auto', "&::-webkit-scrollbar":{
                    display: 'none'
                  },
                  scrollbarWidth: "none",

                }}>
                    {taskComponent("Share Video of Allama in Facebook Muslim Groups", shareMuslimVideosOfAllamaLink, setShareMuslimVideosOfAllamaLink, shareMuslimVideosOfAllamaLinkError, shareMuslimVideosOfAllamaCaption, setShareMuslimVideosOfAllamaCaption, shareMuslimVideosOfAllamaCaptionError)}
                    {taskComponent("Share Video of Allama in Facebook General Groups", shareNonMuslimVideosOfAllamaLink, setShareNonMuslimVideosOfAllamaLink, shareNonMuslimVideosOfAllamaLinkError, shareNonMuslimVideosOfAllamaCaption, setShareNonMuslimVideosOfAllamaCaption, shareNonMuslimVideosOfAllamaCaptionError)}
                
                {taskComponent("Like And Comment on Facebook Page", likeAndCommentOnFBPageLink, setLikeAndCommentOnFBPageLink, likeAndCommentOnFBPageLinkError)}
                {taskComponent("Mention Individuals in Comments", mentionIndividualsInCommentsLink, setMentionIndividualsInCommentsLink, mentionIndividualsInCommentsLinkError)}
                {   
                    (selectedDate < dayjs(MEN_TWO_DISTRICTS_TASK_DATE) && toggle === 'Men') ?
                    taskComponent("Remix Short on Youtube", remixYTLink, setRemixYTLink, remixYTLinkError, remixYTCaption, setRemixYTCaption, remixYTCaptionError) :
                    (
                        <>
                            {taskComponent("Remix Short on Youtube (District 1)", remixYTLink, setRemixYTLink, remixYTLinkError, remixYTCaption, setRemixYTCaption, remixYTCaptionError)}
                            {taskComponent("Remix Short on Youtube (District 2)", remixYTLinkD2, setRemixYTLinkD2, remixYTLinkD2Error, remixYTCaptionD2, setRemixYTCaptionD2, remixYTCaptionD2Error)}
                        </>
                    )

                }
                {
                    (selectedDate < dayjs(MEN_TWO_DISTRICTS_TASK_DATE)&& toggle === 'Men') ? 
                    taskComponent("Remix Reel on Facebook", remixFBLink, setRemixFBLink, remixFBLinkError, remixFBCaption, setRemixFBCaption, remixFBCaptionError):
                    (
                        <>
                            {taskComponent("Remix Reel on Facebook (District 1)", remixFBLink, setRemixFBLink, remixFBLinkError, remixFBCaption, setRemixFBCaption, remixFBCaptionError)}
                            {taskComponent("Remix Reel on Facebook (District 2)", remixFBLinkD2, setRemixFBLinkD2, remixFBLinkD2Error, remixFBCaptionD2, setRemixFBCaptionD2, remixFBCaptionD2Error)}
                        </>
                    )
                }
                {
                    (selectedDate < dayjs(MEN_TWO_DISTRICTS_TASK_DATE)&& toggle === 'Men') ? 
                    taskComponent("Remix Reel on Instagram", remixInstaLink, setRemixInstaLink, remixInstaLinkError, remixInstaCaption, setRemixInstaCaption, remixInstaCaptionError) :
                    (
                        <>
                            {taskComponent("Remix Reel on Instagram (District 1)", remixInstaLink, setRemixInstaLink, remixInstaLinkError, remixInstaCaption, setRemixInstaCaption, remixInstaCaptionError)}
                            {taskComponent("Remix Reel on Instagram (District 2)", remixInstaLinkD2, setRemixInstaLinkD2, remixInstaLinkD2Error, remixInstaCaptionD2, setRemixInstaCaptionD2, remixInstaCaptionD2Error)}
                        </>
                    )
                }
                {taskComponent("Share Video on WhatsApp", sharedVideosOnWALink, setSharedVideosOnWALink, sharedVideosOnWALinkError, sharedVideosOnWACaption, setSharedVideosOnWACaption, sharedVideosOnWACaptionError)}
                {taskComponent("Share Dars Link on Status", sharedDarsLink, setSharedDarsLink, sharedDarsLinkError)}
                {
                    selectedDate >= dayjs(twitterTaskDate)  ? 
                        taskComponent("ReTweet On X", reTweetVideoLink, setReTweetVideoLink, reTweetVideoLinkError) :
                        <></>
                }

                </Paper>
                <Box style={{display: 'flex', justifyContent: 'center', padding: "0.5rem 0rem 0rem 0rem "}}>
                    <Button style={{...buttonStyle, backgroundColor: red}}
                        onClick={() => {
                            props.updateTaskDialogStatus()
                        }}
                    >Cancel</Button>
                    <Button style={buttonStyle}
                        onClick={() =>{
                            handleAdd()
                        }}
                    >Done</Button>
                    
                </Box>
                </>
            </DialogContent>

        </Dialog>
    </>
  )
}

function taskComponent(taskTitle, linkValue, linkFunc, linkError='', captionValue = null, captionFunc = null, captionError = ''){
    return (
        <>
            <Paper style={{padding: '0.3rem', marginTop: '0.3rem', color: headingBlue, border: '0.5px solid #D3D3D3', elevation: 5}}>
                <Grid container spacing={1} >
                    <Grid item xs={5} sm={5} style={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                        <Typography style={{textAlign: 'left'}}>{taskTitle}</Typography>
                    </Grid>
                    <Grid item xs={7} sm={7} style={{display: 'flex', alignItems: 'center'}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                {taskTitle === 'Share Dars Link on Status' ? 
                                <CustomTextField placeHolder='Link & Caption' regular={true} value={linkValue} setFunc={linkFunc}></CustomTextField> :
                                <CustomTextField placeHolder='Link' regular={true} value={linkValue} setFunc={linkFunc}></CustomTextField>}
                                <Typography style={{...errorStyleText, marginLeft: '0.2rem'}}>{linkError}</Typography>
                            </Grid>
                            { captionValue || captionFunc ? 
                                <Grid item xs={12} sm={12}>
                                    <CustomTextField placeHolder='Caption' regular={true} value={captionValue} setFunc={captionFunc}></CustomTextField>
                                    <Typography style={{...errorStyleText, marginLeft: '0.2rem'}}>{captionError}</Typography>
                                </Grid> : 
                                <></>
                            }

                        </Grid>
                    </Grid>

                </Grid>
            </Paper>
        </>
    )
}

export default AddDailyTaskDialogBox