import React from 'react'
import Header from './Headers'
import { useNavigate } from 'react-router-dom';
import { headingBlue, maroon, pureWhite, red, textColor, yellowish } from '../assets/colors';
import Loader from './Loader';
import { Box, Container, Grid, Grid2, Paper, Typography } from '@mui/material';
import CustomTextField from './CustomTextField';
import { addNewDistrictLeader, fetchDistricts, fetchStates, fetchSupervisorDetailsForDistrict, fetchWORKerDetails, getDistrictAvailability } from '../backend/backendApis';
import HttpService from '../backend/requestApis';
import CustomDropDown from './CustomDropDown';
import { validateContact, validateDistrict, validateState } from '../utils/validations';
import BackArrow from './BackArrow';

const DistrictSelectionForm = () => {
	const navigate = useNavigate();

	const [contact, setContact] = React.useState("");
	const [WORKerDetails, setWORKerDetails] = React.useState("");
	const [isVerified, setIsVerified] = React.useState(false);
	const [stateList, setStateList] = React.useState([]);
	// const [secondStateList, setSecondStateList] = React.useState([])
	const [districtList, setDistrictList] = React.useState([]);
	const [secondDistrictList, setSecondDistrictList] = React.useState([]);
	const [selectedState, setSelectedState] = React.useState("");
	const [secondSelectedState, setSecondSelectedState] = React.useState("");
	const [selectedDistrict, setSelectedDistrict] = React.useState("");
	const [secondSelectedDistrict, setSecondSelectedDistrict] = React.useState("");
	const [isLoader, setisLoader] = React.useState(false);
	const [contactError, setContactError] = React.useState(null);
	const [stateError, setStateError] = React.useState(null);
	const [districtError, setDistrictError] = React.useState(null);
	const [secondStateError, setSecondStateError] = React.useState(null);
	const [secondDistrictError, setSecondDistrictError] = React.useState(null);
	const [submitError, setSubmitError] = React.useState(null);
	const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
	const [proceedText, setProceedText] = React.useState(
		"* Click on Proceed if your details are correct."
	);

	const [selectedSupervisorDetail, setSelectedSupervisorDetails] = React.useState([])
	const [districtAvailibility, setDistrictAvailibility ] = React.useState(0)

	React.useEffect(() => {
		setisLoader(() => true);
		setIsFormSubmitted(false);
		setIsVerified(false);
		// const state = State.getStatesOfCountry('IN')
		// setStateList(state)
		setisLoader(() => false);
	}, []);

	const handleContactChange = (contact) => {
		setContact(contact);
	};

	const handleSelectedState = (state, count) => {
		try {
			setisLoader(true);
			if (count == 1) {
				setSelectedState(state);
                setSelectedDistrict(null)
			} else {
                setSecondSelectedDistrict(() => null)
				setSecondSelectedState(state);
				
			}

			let options = {
				url: fetchDistricts,
				method: "GET",
				params: {
					state: state,
					gender: WORKerDetails?.["gender"],
				},
			};

			if (count === 2){
				options['params']['selectedDistrict'] = selectedDistrict
				options['params']['selectedState'] = selectedState
			}
			HttpService(options)
				.then((data) => {
					var districtArray = data.data.data;
					var districtList = [];
					districtArray.map((ele) => {
						let obj = {
							name: ele,
						};
						districtList.push(obj);
					});
					if (count == 1) {
						setDistrictList(districtList);
					} else {
						if(data.data.data.length === 0){
							setSecondDistrictList(() => []);
						} else{
							setSecondDistrictList(districtList);
						} 
					}

					setisLoader(false);
				})
				.catch((err) => {
					console.log("Error in fetching the district list.", err);
					setisLoader(() => false);
				});
		} catch (err) {
			console.log("Error in handling selected state: ", err);
			setisLoader(false);
		}
	};

	const handleSelectedDistrict = (district,  count = 1, state= selectedState,) => {
		if (count == 1) {
			setSelectedDistrict(district);
			setSecondSelectedState(null);
			setSecondSelectedDistrict(null);
			setisLoader(true)
			let options = {
				url: fetchStates,
				method: 'GET',
				params: {
					selectedDistrict: district,
					'selectedState': state,
					gender: WORKerDetails?.['gender']
				}
			}
			HttpService(options).then(data => {
				var statesArray  = data.data.data
				var stateList = []
				if(statesArray.length > 0){
					statesArray?.map(ele => {
						let obj = {
							'name': ele
						}
						stateList.push(obj)
					})
					setStateList(stateList)
					setIsVerified(true)
				} else {
					setProceedText('All districts have been assigned successfully. Best of luck for next time!')
				}
				setisLoader(false)
			}).catch(err => {
				console.log('Error in fetching the state list.', err)
				setisLoader(() => false)
			})
		} else {
			setSecondSelectedDistrict(district);
		}
	};

	const validateGetWORKerDetails = () => {
		let isValid = true;
		let contactValidate = validateContact(contact);
		if (contactValidate != "") {
			setContactError(contactValidate);
			isValid = false;
		} else {
			setContactError("");
		}
		return isValid;
	};

	const ValidateSubmit = () => {
		let isValid = true;
		let districtValidationResult = validateDistrict(selectedDistrict);
		let stateValidationResult = validateState(selectedState);

		if (districtValidationResult != "") {
			setDistrictError(districtValidationResult);
			isValid = false;
		} else {
			setDistrictError("");
		}

		if (stateValidationResult != "") {
			setStateError(stateValidationResult);
			isValid = false;
		} else {
			setStateError("");
		}

		if( secondSelectedDistrict || secondSelectedState){
			let secondDistrictValidationResult = validateDistrict(
				secondSelectedDistrict
			);
			let secondStateValidationResult = validateState(secondSelectedState);

			if (secondDistrictValidationResult != "") {
				setSecondDistrictError(secondDistrictValidationResult);
				isValid = false;
			} else {
				setSecondDistrictError("");
			}

			if (secondStateValidationResult != "") {
				setSecondStateError(secondStateValidationResult);
				isValid = false;
			} else {
				setSecondStateError("");
			}

			if (secondSelectedDistrict === selectedDistrict) {
				setSubmitError(
					"First and second District cannot be same. Please choose a different District."
				);
				isValid = false;
			} else {
				setSubmitError("");
			}
		}
		return isValid;
	};

	const getSupervisorsDetailsForSelectedDistrict = () => {
		return new Promise((resolve, reject) => {
			let params = {
				selectedDistrict: selectedDistrict,
				selectedState: selectedState
			}
			if( secondSelectedDistrict && secondSelectedState){
				params['secondSelectedDistrict'] = secondSelectedDistrict
				params['secondSelectedState'] = secondSelectedState
			}
			let options = {
				url: fetchSupervisorDetailsForDistrict,
				method: "GET",
				params: params
			}
			HttpService(options).then((data) => {
				setSelectedSupervisorDetails(data?.data?.data)
				resolve()
			}).catch(err => {
				console.log("Error in fetching supervisors details")
				reject({code: 600})
			})
		})
	}

	const handleSubmit = async () => {
		let isValid = await ValidateSubmit();

		if (isValid) {
			try {
				setisLoader(true);
				let options = {
					url: addNewDistrictLeader,
					method: "POST",
					payload: {
						name: WORKerDetails.name ? WORKerDetails.name : "",
						contactNumber: contact,
						gender: WORKerDetails.gender ? WORKerDetails.gender : "",
						district: WORKerDetails.district ? WORKerDetails.district : "",
						chapter: WORKerDetails.chapter ? WORKerDetails.chapter : ""
					},
				};
				if( districtAvailibility === 1){
					options['payload']['secondSelectedChapter'] = secondSelectedState
					options['payload']['secondSelectedDistrict'] = secondSelectedDistrict
				} else {
					options['payload']['selectedChapter'] = selectedState
					options['payload']['selectedDistrict'] = selectedDistrict
					options['payload']['secondSelectedChapter'] = secondSelectedState
					options['payload']['secondSelectedDistrict'] = secondSelectedDistrict
				}

				setisLoader(() => true);
				HttpService(options)
					.then(async () => {
						console.log("Successfully created the District Leader Assignment.");
						await getSupervisorsDetailsForSelectedDistrict()
						setIsFormSubmitted(true);
						setisLoader(() => false);
						// navigate('/submit')
					})
					.catch((err) => {
						console.log("Error in creating lead", err);
						if (err.status == 404 || err.status == 409) {
							setSubmitError(err?.response?.data?.description);
							setSelectedDistrict(null);
							setSelectedState(null);
							setSecondSelectedDistrict(null);
							setSecondSelectedState(null);
						} else if( err?.code === 600){
							setIsFormSubmitted(true);
						}

						setisLoader(() => false);
					});
			} catch (err) {
				console.log("Error in handling Submit: ", err);
				setisLoader(() => false);
			}
		}
	};

	const getWORKerDetails = async () => {
		try {
			setIsVerified(false);
			setWORKerDetails(null);
			setSubmitError(null);
			setSelectedDistrict("");
			setSelectedState("");
			setSecondSelectedDistrict("");
			setSecondSelectedState("");
			setDistrictError("");
			setStateError("");
			setSecondDistrictError("");
			setSecondStateError("");
			setStateList([]);
			setDistrictList([]);
			setProceedText("* Click on Proceed if your details are correct.");
			let isValid = await validateGetWORKerDetails();
			if (isValid) {
				setisLoader(true);
				let options = {
					url: fetchWORKerDetails,
					method: "GET",
					params: {
						contactNumber: contact,
					},
				};
				HttpService(options)
					.then((data) => {
						console.log("fetched the WORKer details.", data.data.data);
						setWORKerDetails(data.data.data);
						setisLoader(false);
					})
					.catch((err) => {
						console.log("Error in fetching the WORKer details.", err);
						if (err.status == 404) {
							setContactError(err?.response?.data?.description);
						}
						setisLoader(() => false);
					});
			}
		} catch (err) {
			console.log("Error in handling get WORKer Details: ", err);
			setisLoader(() => false);
		}
	};

    const handleProceed = () => {
        try{
            setisLoader(true)
            setSelectedDistrict(null)
            setDistrictError('')
            setSelectedState(null)
            setStateError('')
            setSecondSelectedDistrict(null)
            setSecondDistrictError('')
            setSecondSelectedState(null)
            setSecondStateError('')
            setSubmitError('')
			let options = {
				url: getDistrictAvailability,
				method: 'GET',
				params: {
					contactNumber: contact
				}
			}
			HttpService(options).then(data => {
				data = data?.data?.data
				console.log("districtAvailibility   1: ", data['districtAvailability'])
				setDistrictAvailibility(() => data['districtAvailability'])
				if ( data['districtAvailability'] === 1 ){
					setSelectedState(data['selectedChapter'])
					handleSelectedDistrict(data['selectedDistrict'], 1, data['selectedChapter'])
				} else if(data['districtAvailability'] === 2){
					console.log("districtAvailibility22222222222222: ", data['districtAvailibility'])
					let options = {
						url: fetchStates,
						method: 'GET',
						params: {
							gender: WORKerDetails?.['gender']
						}
					}
					HttpService(options).then(data => {
						var statesArray  = data.data.data
						var stateList = []
						if(statesArray.length > 0){
							statesArray?.map(ele => {
								let obj = {
									'name': ele
								}
								stateList.push(obj)
							})
							setStateList(stateList)
							setIsVerified(true)
						} else {
							setProceedText('All districts have been assigned successfully. Best of luck for next time!')
							setIsVerified(true)
						}
					}).catch(err => {
						    console.log('Error in fetching the state list.', err)
						    setisLoader(() => false)
						})

						setisLoader(false)
					} else {
						setIsVerified(true)
						setisLoader(false)
					}
			}).catch(err => {
				console.log('Error in fetching the state list.', err)
			    setisLoader(() => false)

			})
			
            // let options = {
            //     url: fetchStates,
            //     method: 'GET',
            //     params: {
            //         gender: WORKerDetails?.['gender']
            //     }
            // }
            // HttpService(options).then(data => {
            //     console.log('fetched the states list.', data.data.data)
            //     var statesArray  = data.data.data
            //     var stateList = []
            //     if(statesArray.length > 0){
            //         statesArray?.map(ele => {
            //             let obj = {
            //                 'name': ele
            //             }
            //             stateList.push(obj)
            //         })
            //         setStateList(stateList)
            //         setIsVerified(true)
            //     } else {
            //         setProceedText('All districts have been assigned successfully. Best of luck for next time!')
            //     }
            //     setisLoader(false)
            // }).catch(err => {
            //     console.log('Error in fetching the state list.', err)
            //     setisLoader(() => false)
            // })
        } catch(err) {
            console.log("Error in handling proceed.", err)
            isLoader(false)
        }
    }

    var containerStyle = { 
        margin: "auto", 
        color: headingBlue, 
        fontSize: "1rem", 
        maxWidth: "25rem", 
        overflow:"auto", 
        padding: "1.25rem 1.25rem 0.2rem 1.25rem",
        marginTop: '2.3rem',
        // height: "85vh",
        // "&::-webkit-scrollbar":{
        //     display: 'none'
        // },
        // scrollbarWidth: "none"
    }
    var imgContainer = {
        textAlign: 'center',
        padding: '0.4rem'
    }
    var imgStyle = {
        width: '35%'
    }
    var paperStyle= {
        color: headingBlue,
        padding: '0.4rem',
        boxShadow: '-0.05rem 0.05rem 0.1rem 0.1rem #D9D9D9'
    }
    var typographyStyle= {
        fontSize: "0.9rem",
        textAlign: "left",
        padding: "0rem 0rem 0rem 1rem"
    }
    var buttonContainer = {
        textAlign: 'center',
        padding: "0rem"
    }
    var buttonStyle ={
        marginTop: '0.5rem',
        backgroundColor: headingBlue,
        padding: '0.4rem 0.6rem 0.4rem 0.6rem',
        color: pureWhite,
        border: '1px solid ',
        borderRadius: '0.4rem',
        fontSize: '0.9rem'

    }

	var errorStyleText = {
		color: red,
		padding: "0.5rem 0rem 0rem 0rem",
		// marginTop: 1,
		fontSize: 11,
		// marginLeft: 5
	};

	return (
		<>
			<div style={{ position: "fixed", top: 0, left: 0, width: "100%" }}>
				<Header title="District Selection Form"></Header>
			</div>

			{isLoader ? (
				<Loader></Loader>
			) : (
				<>
					<div style={containerStyle}>
						<div style={imgContainer}>
							<img
								src={require("../assets/images/WORK_logo.png")}
								style={imgStyle}
							></img>
						</div>
						<BackArrow/>
						<Paper style={paperStyle}>
							{!isFormSubmitted && (
								<Container
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										textAlign: "center",
										alignItems: "center",
										padding: "0.7rem 0.4rem 0rem 0.4rem",
									}}
								>
									<Typography style={{ padding: "0.2rem", fontSize: "1rem" }}>
										{" "}
										Enter Your Contact Number
									</Typography>
									<CustomTextField
										setFunc={handleContactChange}
										// style={customTextFieldStyle}
										value={contact}
									></CustomTextField>
									<Typography style={errorStyleText}>{contactError}</Typography>
									<div style={buttonContainer}>
										<button
											style={buttonStyle}
											onClick={() => {
												getWORKerDetails();
											}}
										>
											{" "}
											Get Your Details
										</button>
									</div>
								</Container>
							)}


            {
                WORKerDetails && !isVerified && !isFormSubmitted && (
                    <>
                    <Paper style={{marginTop: "0.5rem", padding: "0.25rem 0.25rem 0.4rem 0.25rem", color: headingBlue,  boxShadow: '-0.01rem 0.01rem 0.2rem 0.05rem #D9D9D9'}}>
                        <Container style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', padding: "0.7rem 0.4rem 0rem 0.4rem"}}>
                        <Typography style={{fontSize: "1rem", fontWeight: 600, color: yellowish}}>Verify Your Details</Typography>
                            <Grid container spacing={3} style={{padding: "0.2rem"}}>
                                <Grid item xs={4} s={4} style={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                                    <Typography style={{wordWrap: "break-word", whiteSpace: "normal"}}>{WORKerDetails.name}</Typography>
                                </Grid>
                                <Grid item xs={2} s={2} style={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                                    <Typography style={{wordWrap: "break-word", whiteSpace: "normal"}}>{WORKerDetails.gender}</Typography>
                                </Grid>
                                <Grid item xs={3} s={3} style={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                                    <Typography style={{wordWrap: "break-word", whiteSpace: "normal"}}>{WORKerDetails.district}</Typography>
                                </Grid>
                                <Grid item xs={3} s={3} style={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                                    <Typography style={{wordWrap: "break-word", whiteSpace: "normal"}}>{WORKerDetails.chapter}</Typography>
                                </Grid>

                            </Grid>
                            <div style={buttonContainer}>
                                <button style={buttonStyle}
                                    onClick={() => {
                                        handleProceed()
                                    }}
                                > Proceed</button>
                            </div>
                            <Typography style={{...errorStyleText, fontWeight: 600}}>{proceedText}</Typography>
                        </Container>
                    </Paper>
                    </>
                )
            }
            {
                isVerified && !isFormSubmitted && (

					<>
						{districtAvailibility === 0 &&
							<Box style={{display:'flex', justifyContent: 'center', padding: '0.2rem'}}>
								<Typography style={{...errorStyleText, fontSize: '1rem'}}>JazakAllah, You have selected both the districts.</Typography>
							</Box>
						}
						{
							districtAvailibility === 1 &&
							    <Paper style={{marginTop: "0.5rem", padding: "0.25rem 0.25rem 0.4rem 0.25rem", color: headingBlue,  boxShadow: '-0.01rem 0.01rem 0.2rem 0.05rem #D9D9D9'}}>
									<Container style={{dispaly: 'flex', justifyContent: 'center', textAlign: 'center', padding: "0.6rem 0.4rem 0rem 0.4rem"}}>
										<Grid container spacing={2} style={{ padding: "0.2rem 0.5rem 0rem 0.3rem"}}>
											<Grid item xs={6} sm={6} style={{pointerEvents: 'none', opacity: 0.5}}>
												<Typography style={{...typographyStyle, padding: "0rem 0rem 0rem 0.3rem" }}> State</Typography>
												<CustomDropDown key={'state'} list={stateList} value={selectedState} setFunc={handleSelectedState} isCount={true} count={1}></CustomDropDown>
												<Typography style={{...errorStyleText, padding: "0.15rem 0rem 0rem 1rem", textAlign: "left"}}>{stateError}</Typography>
											</Grid>
											<Grid item xs={6} sm={6} style={{pointerEvents: 'none', opacity: 0.5}}>
												<Typography style={{...typographyStyle, padding: "0rem 0rem 0rem 0.3rem"}}> District</Typography>
												<CustomDropDown key={'district'} list={districtList} value={selectedDistrict} setFunc={handleSelectedDistrict} isCount={true} count={1}></CustomDropDown>
												<Typography style={{...errorStyleText, padding: "0.15rem 0rem 0rem 1rem", textAlign: "left"}}>{districtError}</Typography>
											</Grid>
											<Grid item xs={6} sm={6} style={{padding: '0.2rem 0rem 0rem 1rem' }}>
								
												<Typography style={{...typographyStyle, padding: "0rem 0rem 0rem 0.3rem"}}> State</Typography>
												<CustomDropDown key={'secondState'} list={stateList} value={secondSelectedState} setFunc={handleSelectedState} isCount={true} count={2}></CustomDropDown>
												<Typography style={{...errorStyleText, padding: "0.15rem 0rem 0rem 1rem", textAlign: "left"}}>{secondStateError}</Typography>
											</Grid>
											<Grid item xs={6} sm={6} style={{padding: '0.2rem 0rem 0rem 1rem' }}>
												<Typography style={{...typographyStyle, padding: "0rem 0rem 0rem 0.3rem"}}> District</Typography>
												<CustomDropDown key={'secondDistrict'} list={secondDistrictList} value={secondSelectedDistrict} setFunc={handleSelectedDistrict} isCount={true} count={2}></CustomDropDown>
												<Typography style={{...errorStyleText, padding: "0.15rem 0rem 0rem 1rem", textAlign: "left"}}>{secondDistrictError}</Typography>
											</Grid>
										</Grid>
										<div style={buttonContainer}>
											<button style={buttonStyle}
												onClick={() => {
													handleSubmit()
												}}
											> Submit</button>
										</div>
									</Container>
								</Paper>
						}
						{
							districtAvailibility === 2 && 
							<Paper style={{marginTop: "0.5rem", padding: "0.25rem 0.25rem 0.4rem 0.25rem", color: headingBlue,  boxShadow: '-0.01rem 0.01rem 0.2rem 0.05rem #D9D9D9'}}>
									<Container style={{dispaly: 'flex', justifyContent: 'center', textAlign: 'center', padding: "0.6rem 0.4rem 0rem 0.4rem"}}>
										<Grid container spacing={2} style={{ padding: "0.2rem 0.5rem 0rem 0.3rem"}}>
											<Grid item xs={6} sm={6}>
												<Typography style={{...typographyStyle, padding: "0rem 0rem 0rem 0.3rem" }}> State</Typography>
												<CustomDropDown key={'state'} list={stateList} value={selectedState} setFunc={handleSelectedState} isCount={true} count={1}></CustomDropDown>
												<Typography style={{...errorStyleText, padding: "0.15rem 0rem 0rem 1rem", textAlign: "left"}}>{stateError}</Typography>
											</Grid>
											<Grid item xs={6} sm={6}>
												<Typography style={{...typographyStyle, padding: "0rem 0rem 0rem 0.3rem"}}> District</Typography>
												<CustomDropDown key={'district'} list={districtList} value={selectedDistrict} setFunc={handleSelectedDistrict} isCount={true} count={1}></CustomDropDown>
												<Typography style={{...errorStyleText, padding: "0.15rem 0rem 0rem 1rem", textAlign: "left"}}>{districtError}</Typography>
											</Grid>
											<Grid item xs={6} sm={6} style={{padding: '0.2rem 0rem 0rem 1rem' }}>
								
												<Typography style={{...typographyStyle, padding: "0rem 0rem 0rem 0.3rem"}}> State</Typography>
												<CustomDropDown key={'secondState'} list={stateList} value={secondSelectedState} setFunc={handleSelectedState} isCount={true} count={2}></CustomDropDown>
												<Typography style={{...errorStyleText, padding: "0.15rem 0rem 0rem 1rem", textAlign: "left"}}>{secondStateError}</Typography>
											</Grid>
											<Grid item xs={6} sm={6} style={{padding: '0.2rem 0rem 0rem 1rem' }}>
												<Typography style={{...typographyStyle, padding: "0rem 0rem 0rem 0.3rem"}}> District</Typography>
												<CustomDropDown key={'secondDistrict'} list={secondDistrictList} value={secondSelectedDistrict} setFunc={handleSelectedDistrict} isCount={true} count={2}></CustomDropDown>
												<Typography style={{...errorStyleText, padding: "0.15rem 0rem 0rem 1rem", textAlign: "left"}}>{secondDistrictError}</Typography>
											</Grid>
										</Grid>
										<div style={buttonContainer}>
											<button style={buttonStyle}
												onClick={() => {
													handleSubmit()
												}}
											> Submit</button>
										</div>
									</Container>
								</Paper>
						}
					</>
                    // <>
                    //     <Paper style={{marginTop: "0.5rem", padding: "0.25rem 0.25rem 0.4rem 0.25rem", color: headingBlue,  boxShadow: '-0.01rem 0.01rem 0.2rem 0.05rem #D9D9D9'}}>
                    //         <Container style={{dispaly: 'flex', justifyContent: 'center', textAlign: 'center', padding: "0.6rem 0.4rem 0rem 0.4rem"}}>
                    //         <Typography style={{fontSize: "1rem", fontWeight: 600, color: yellowish}}>Choose Your Desired State and District</Typography>
                    //         <Grid container spacing={2} style={{ padding: "0.2rem 0.5rem 0rem 0.3rem"}}>
                    //             <Grid item xs={6} sm={6}>
                    //                 <Typography style={{...typographyStyle, padding: "0rem 0rem 0rem 0.3rem" }}> State</Typography>
                    //                 <CustomDropDown key={'state'} list={stateList} value={selectedState} setFunc={handleSelectedState} isCount={true} count={1}></CustomDropDown>
                    //                 <Typography style={{...errorStyleText, padding: "0.15rem 0rem 0rem 1rem", textAlign: "left"}}>{stateError}</Typography>
                    //             </Grid>
                    //             <Grid item xs={6} sm={6}>
                    //                 <Typography style={{...typographyStyle, padding: "0rem 0rem 0rem 0.3rem"}}> District</Typography>
                    //                 <CustomDropDown key={'district'} list={districtList} value={selectedDistrict} setFunc={handleSelectedDistrict} isCount={true} count={1}></CustomDropDown>
                    //                 <Typography style={{...errorStyleText, padding: "0.15rem 0rem 0rem 1rem", textAlign: "left"}}>{districtError}</Typography>
                    //             </Grid>
					// 			<Grid item xs={6} sm={6} style={{padding: '0.2rem 0rem 0rem 1rem' }}>
					// 				<Typography style={{...typographyStyle, padding: "0rem 0rem 0rem 0.3rem"}}> State</Typography>
					// 				<CustomDropDown key={'secondState'} list={stateList} value={secondSelectedState} setFunc={handleSelectedState} isCount={true} count={2}></CustomDropDown>
					// 				<Typography style={{...errorStyleText, padding: "0.15rem 0rem 0rem 1rem", textAlign: "left"}}>{secondStateError}</Typography>
					// 			</Grid>
					// 			<Grid item xs={6} sm={6} style={{padding: '0.2rem 0rem 0rem 1rem' }}>
					// 				<Typography style={{...typographyStyle, padding: "0rem 0rem 0rem 0.3rem"}}> District</Typography>
					// 				<CustomDropDown key={'secondDistrict'} list={secondDistrictList} value={secondSelectedDistrict} setFunc={handleSelectedDistrict} isCount={true} count={2}></CustomDropDown>
					// 				<Typography style={{...errorStyleText, padding: "0.15rem 0rem 0rem 1rem", textAlign: "left"}}>{secondDistrictError}</Typography>
					// 			</Grid>
                    //         </Grid>
                    //         <Typography style={{...errorStyleText, fontSize: '0.8rem'}}>{submitError}</Typography>
                    //         <div style={buttonContainer}>
                    //             <button style={buttonStyle}
                    //                 onClick={() => {
                    //                     handleSubmit()
                    //                 }}
                    //             > Submit</button>
                    //         </div>
                    //     </Container>
                    // </Paper>
                    // </>
                )
            }

            {
                isFormSubmitted && (
                    <>
                        {WORKerDetails?.gender === 'Male' ?
                            <Typography style={{fontSize: "1.2rem", padding: "3rem 0.5rem 2rem 0.5rem", textAlign: 'center', color: maroon}}> 
							Alhamdulillah, you have successfully chosen Districts {selectedDistrict} and {secondSelectedDistrict}.
							<br></br>
							<Typography style={{fontSize: "1rem", padding: "0.7rem 0.4rem 0.4rem 2rem", color: headingBlue}}>
								Your Supervisor Details
							</Typography>
							<Grid container spacing={1} sx={{textAlign: 'left', padding: "0.2rem 0.4rem 0.4rem 2rem"}}>
								<Grid item xs={4} sm={4} sx={{padding: '0rem'}}>
									<Typography style={{fontSize: '0.9rem', color: headingBlue}}>
										{selectedDistrict}
									</Typography>
								</Grid>
								<Grid item xs={4} sm={4} sx={{padding: '0rem'}}>
									<Typography style={{fontSize: '0.9rem', color: headingBlue}}>
										{selectedSupervisorDetail[0]?.maleSupervisorName}
									</Typography>
								</Grid>
								<Grid item xs={4} sm={4} sx={{padding: '0rem'}}>
									<Typography style={{fontSize: '0.9rem', color: headingBlue}}>
										{selectedSupervisorDetail[0]?.maleSupervisorContact}
									</Typography>
								</Grid>
								<Grid item xs={4} sm={4} sx={{padding: '0rem'}}>
									<Typography style={{fontSize: '0.9rem', color: headingBlue}}>
										{secondSelectedDistrict}
									</Typography>
								</Grid>
								<Grid item xs={4} sm={4} sx={{padding: '0rem'}}>
									<Typography style={{fontSize: '0.9rem', color: headingBlue}}>
										{selectedSupervisorDetail[1]?.maleSupervisorName}
									</Typography>
								</Grid>
								<Grid item xs={4} sm={4} sx={{padding: '0rem'}}>
									<Typography style={{fontSize: '0.9rem', color: headingBlue}}>
										{selectedSupervisorDetail[1]?.maleSupervisorContact}
									</Typography>
								</Grid>

							</Grid>
							</Typography> :
                            <Typography style={{fontSize: "1.2rem", padding: "3rem 0.5rem 2rem 0.5rem", textAlign: 'center', color: maroon}}> 
								Alhamdulillah, you have successfully chosen Districts {selectedDistrict} and {secondSelectedDistrict}.
								<br></br>
								<Typography style={{fontSize: "1rem", padding: "0.7rem 0.4rem 0.4rem 2rem", color: headingBlue}}>
									Your Supervisor Details
								</Typography>
								<Grid container spacing={1} sx={{textAlign: 'left', padding: "0.2rem 0.4rem 0.4rem 2rem"}}>
									<Grid item xs={4} sm={4} sx={{padding: '0rem'}}>
										<Typography style={{fontSize: '0.9rem', color: headingBlue}}>
											{selectedDistrict}
										</Typography>
									</Grid>
									<Grid item xs={4} sm={4} sx={{padding: '0rem'}}>
										<Typography style={{fontSize: '0.9rem', color: headingBlue}}>
											{selectedSupervisorDetail[0]?.femaleSupervisorName}
										</Typography>
									</Grid>
									<Grid item xs={4} sm={4} sx={{padding: '0rem'}}>
										<Typography style={{fontSize: '0.9rem', color: headingBlue}}>
											{selectedSupervisorDetail[0]?.femaleSupervisorContact}
										</Typography>
									</Grid>
									<Grid item xs={4} sm={4} sx={{padding: '0rem'}}>
										<Typography style={{fontSize: '0.9rem', color: headingBlue}}>
											{secondSelectedDistrict}
										</Typography>
									</Grid>
									<Grid item xs={4} sm={4} sx={{padding: '0rem'}}>
										<Typography style={{fontSize: '0.9rem', color: headingBlue}}>
											{selectedSupervisorDetail[1]?.femaleSupervisorName}
										</Typography>
									</Grid>
									<Grid item xs={4} sm={4} sx={{padding: '0rem'}}>
										<Typography style={{fontSize: '0.9rem', color: headingBlue}}>
											{selectedSupervisorDetail[1]?.femaleSupervisorContact}
										</Typography>
									</Grid>

								</Grid>
								</Typography>
                        }
                    </>
                )
            }
        </Paper>
    </div>
    </>
    )}
    </>
  )
}

export default DistrictSelectionForm;
